import { Interface } from "@ethersproject/abi";
import { hex } from "../../../oracle/src/lib/buffer";
import { Hex24, Hex32 } from "../../../oracle/src/lib/types";
import { toReactorAddress } from "../../../oracle/src/rpc/lib/address";
import { FarmExistsEvent } from "../contracts/referralFarmsV1";
import { rpcCall, RpcRoute } from "./rpcHelpers";

const iface = new Interface([
  'function getFarmTokenSize(bytes32 farmHash, bytes24 referredToken) view returns (uint128)',
]);

export async function getFarmTokenSize(farmHash: Hex32, referredToken: Hex24) : Promise<bigint> {
  const data = iface.encodeFunctionData('getFarmTokenSize', [farmHash, referredToken]);
  return BigInt(await rpcCall(RpcRoute.rpc, hex(toReactorAddress('farmTokenSizeV1Reactor')), data));
}

export interface FarmTokenSize {
  farmHash: Hex32;
  token: Hex24;
  size: bigint;
}

export async function getFarmTokenSizes(events: FarmExistsEvent[]) : Promise<FarmTokenSize[]> {
  const uniques = {};
  const res: FarmTokenSize[] = [];
  for(let { farmHash, referredTokenDefn } of events) {
    const key = `${farmHash}|${referredTokenDefn}`;
    if(uniques[key]) continue;
    const size = await getFarmTokenSize(farmHash, referredTokenDefn);
    res.push({
      farmHash,
      token: referredTokenDefn,
      size,
    })
    uniques[key] = true;
  }
  return res;
} 