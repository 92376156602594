import { useEffect } from "react";
import { useFilteredFarmHashes } from "../hooks/data";
import { useFarmTokenInfo } from "../hooks/farms";
import { useFarmsTrackedRewardValue } from "../oracles/referralFarmsV1";
import ChainAddress from "./chainAddress";
import TokenValue from './tokenValue';

export default function ContractFarm({ farmHash, className }) {
  const { data: filteredFarmHashes } = useFilteredFarmHashes();
  const { data: farmTrackedRewardValue, ensure, error } = useFarmsTrackedRewardValue([farmHash])
  const { referredTokenDefn, rewardTokenDefn } = useFarmTokenInfo(farmHash);

  useEffect(() => {
    if (filteredFarmHashes && filteredFarmHashes.includes(farmHash)) {
        ensure()
    }
  }, [filteredFarmHashes, farmHash])

  return (
    <div className={`${className} flex flex-col`}>
      <div><ChainAddress value={referredTokenDefn} label="referredToken" /></div>
      <div><ChainAddress value={rewardTokenDefn} label="rewardToken" /></div>
      {farmTrackedRewardValue && <div>depositRemaining: <TokenValue value={farmTrackedRewardValue[0]?.reward} /></div>}
    </div>
  );
}