import { uniq } from "lodash";
import React from "react";
import { useUiContext } from "../stores/uiContextStore";
import ContractFarm from "./contractFarm";
import { Cropped } from "./cropped";
import { FarmTokenActivity } from "./farmTokenActivity";
import OracleFarm from "./oracleFarm";

// Best-effort render everything we know about a farm
// This should take an outer-join approach (eg: if farm data shows up on one side and not on the other, it should show)
export default function FarmOuterView({ farmHash }) {
  const [, setFilterHashes] = useUiContext.filterHashes();
  return (
    <div className="mb-3">
      <div className="flex flex-auto">
        <div className="flex-grow font-bold"><Cropped>Farm {farmHash}</Cropped></div>
        <button className="p-0 m-0" onClick={_ => setFilterHashes(v => uniq([...v, farmHash]))}>➕</button>
        <button className="p-0 m-0" onClick={_ => setFilterHashes(v => v.filter(d => d !== farmHash))}>➖</button>
      </div>
      <div className="flex flex-auto text-sm">
        <FarmTokenActivity farmHash={farmHash} className="flex-grow w-2/6 bg-gray-100 text-xs p-1"/>
        <ContractFarm farmHash={farmHash} className="flex-grow ml-1 w-1/6 bg-yellow-100 text-xs p-1"/>
        <OracleFarm farmHash={farmHash} className="flex-grow ml-1 w-4/6 bg-green-100 text-xs p-1" />
      </div>
    </div>
  )
}