import Big from "big.js"
import { get } from "lodash"
import React from "react"
import { Hex20 } from "../../../../oracle/src/lib/types"
import { FarmPositionIncreasedEvent } from "../../oracles/farmPositionsV1"
import { useUiContext } from "../../stores/uiContextStore"
import { Cropped } from "../cropped"
import DynamicComponent from '../dynamicComponent'

export type TableColumnDefinition = Map<string, boolean>


export interface FarmPositionChangeEventWithConfirmation extends FarmPositionIncreasedEvent {
  blockNr: number
  confirmationNr: number | undefined
}

export interface EtherscanTransferEvent {
  blockHash: string
  blockNumber: string
  confirmations: string
  contractAddress: string
  cumulativeGasUsed: string
  from: string
  gas: string
  gasPrice: string
  gasUsed: string
  hash: string
  input: string
  nonce: string
  timeStamp: string
  fmtTime: string
  to: string
  tokenDecimal: string
  tokenName: string
  tokenSymbol: string
  transactionIndex: string
  value: string
}

export interface ParticipantPositionViewModel {
  address: Hex20
  oraclePositionChangedEvents: FarmPositionChangeEventWithConfirmation[]
  etherscanTransferEvents: EtherscanTransferEvent[]
}

const oracleTableRowClassFunc = (model: ParticipantPositionViewModel, row: FarmPositionChangeEventWithConfirmation): string => {
  if (get(row, 'event.name') == "FarmPositionIncreased") {
    return 'bg-lime-200'
  }
  if (get(row, 'event.name') == "FarmPositionDecreased") {
    return 'bg-orange-200	'
  }
  return ""
}

const erc20TableRowClassFunc = (model: ParticipantPositionViewModel, row: EtherscanTransferEvent): string => {
  if (row?.from == model.address) {
    return 'bg-orange-200'
  }

  if (row?.to == model.address) {
    return 'bg-lime-200	'
  }

  return ""
}

export function ParticipantPositionsTable({ model, oracleEventCols, oracleEventFields, etherscanTransferEventCols, etherscanTransferEventFields }: 
  { 
    model: ParticipantPositionViewModel, 
    oracleEventCols: string[],
    oracleEventFields: TableColumnDefinition,
    etherscanTransferEventCols: string[],
    etherscanTransferEventFields: TableColumnDefinition,
  }) {
  return (
    <div>
      <h3 className="mt-5 text-lg">{"Participant: " + model.address.toString()}</h3>
      <h4 className="text-base">Oracle: </h4>
      <table>
        <thead>
          <TableHeadRow cols={oracleEventCols} fields={oracleEventFields} />
        </thead>
        <tbody>
          {model.oraclePositionChangedEvents
            .map((row, i) => <TableRow key={`row-${i}`} 
                                rowi={i} row={row} cols={oracleEventCols} fields={oracleEventFields} 
                                rowClassName={oracleTableRowClassFunc(model, row)} />)}
        </tbody>
      </table>

      <h4 className="text-base">Etherscan</h4>
      <table>
        <thead>
          <TableHeadRow cols={etherscanTransferEventCols} fields={etherscanTransferEventFields} />
        </thead>
        <tbody>
          {model.etherscanTransferEvents
            .map((row, i) => <TableRow key={`row-${i}`} rowi={i} row={row} cols={etherscanTransferEventCols} fields={etherscanTransferEventFields} 
                                rowClassName={erc20TableRowClassFunc(model, row)} />)}
        </tbody>
      </table>
    </div>
  )
}

function TableHeadRow({ cols, fields }: {cols: string[], fields: TableColumnDefinition}) {
  return (
    <tr>
      {cols.filter(c => fields[c]).map(col => {
        return (
          <th className="border border-slate-200 italic text-xs" key={`th-${col}`}>{col}</th>
        )
      })}
    </tr>
  )
}

function TableRow({ rowi, row, cols, fields, rowClassName }: {
  rowi: number,
  row: any,
  cols: string[],
  fields: TableColumnDefinition,
  rowClassName?: string
}) {
  const [numberPrecision] = useUiContext.numberPrecision();
  const [units] = useUiContext.units();

  
  return (
    <tr className={ " " + rowClassName }>
      {cols
        .filter(c => fields[c])
        .map((c, i) => {
          let val;
          // Render a useful value
          if(row[c] != null) {
            let raw = row[c];
            if(raw && raw._isDynComp) {
              val = <DynamicComponent func={raw.func} args={raw.args} />;
            } else if(React.isValidElement(raw)) {
              val = raw;
            } else if(typeof raw === 'object') {
              val = JSON.stringify(raw)
            } else if(typeof raw === 'bigint') {
              // Show big numbers in a way they make some sense
              val = Big(raw.toString()).div(Big(10).pow(units)).toFixed(numberPrecision)
            } else if (raw.toString().length > 24) {
              val = <Cropped>{raw.toString()}</Cropped>;
            } else {
              val = raw.toString()
            }
          } else {
            // Fix render bug due to field selection
            val = '';
          }

          return <td 
            className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs" 
            key={`tr-${rowi}-${i}`}>
              {val}
          </td>
        })}
    </tr>
  )
}
