import React from "react"
import ProofValidate from "./proofValidate"
import { OriginExpired } from "./devtools/origins/originExpired"

type DynamicComponentType = 'ProofValidate' | "OriginExpired"

export function makeDynamicComponent(func: DynamicComponentType, args: any) : DynamicComponent {
  return {
    _isDynComp: true,
    func, 
    args,
  }
}

export interface DynamicComponent {
  _isDynComp: boolean;
  func: DynamicComponentType;
  args: any;
}

export default function DynamicComponent({ func, args } : { func: DynamicComponentType, args: any }) {
  // Render returns
  switch(func) {
    case 'ProofValidate': return <ProofValidate event={args.event} />;
    case 'OriginExpired': return <OriginExpired {...args} />;
    // Add more types here
    default:
      console.trace(func, args);
      throw new Error('unknown type')
  }
}