import React, { useCallback, useEffect, useState } from "react";
import { nr } from "../../../oracle/src/lib/buffer";
import { rpcEncode } from "../../../oracle/src/lib/rpc";
import { Hex32 } from "../../../oracle/src/lib/types";
import { fmtTime } from "../lib/time";
import { getNetworkConfig } from "../stores/sessionStore";
import { error } from "./errorStack";
import ObjectsExplorer from "./objectsExplorer";

let lastSearchId = 0;

async function getBlock(url, search: number | Hex32 | 'latest') {
  let method;
  if(typeof search === 'string' && search.length === 66) {
    method = 'oracle_getDerivedBlockByHash';
  } else if(search === 'latest') {
    method = 'oracle_getDerivedBlockByNumber'
  } else {
    method = 'oracle_getDerivedBlockByNumber'
    search = rpcEncode(search);
  }
  const req = await fetch(`${url}/v1/rpc`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({jsonrpc:"2.0", id: crypto.randomUUID(), method, params:[search]})
  })
  if(req.status !== 200) {
    console.warn(req);
    throw new Error('request failed: '+req.statusText)
  }
  const res = await req.json();
  return parseBlock(res.result);
}

function parseBlock(block: any) {
  const { hash, parentHash, number, bundleHash, stateRoot, receiptHash, logsRoot, logsBloom, closer, confirmationHash, externalsRoot, externalsBloom } = block.header;
  return {
    number: nr(number),
    hash,
    parentHash,
    confirmationHash,
    'closer.fmtTime': fmtTime(closer.timestamp),
    'closer.time': nr(closer.timestamp),
    'closer.number': nr(closer.number),
    'closer.hash': closer.hash,
    stateRoot,
    bundleHash,
    receiptHash,
    logsRoot,
    logsBloom,
    externalsRoot,
    externalsBloom,
  }
}

export default function BlockChainView() {
  const [searchHash, setSearchHash] = useState('');
  const [searchNumber, setSearchNumber] = useState(0);
  const [limit, setLimit] = useState(20);
  const [blocks, setBlocks] = useState([]);

  const doSearch = useCallback(() => {
    setBlocks([]);
    const mySearchId = ++lastSearchId;

    (async () => {
      const conf = await getNetworkConfig();
      // Find start block
      let block;
      if(searchHash === '' && !searchNumber) {
        block = await getBlock(conf.oracles[0], 'latest');
      } else if (searchHash.length > 0) {
        block = await getBlock(conf.oracles[0], searchHash);
      } else if (searchNumber > 0) {
        block = await getBlock(conf.oracles[0], searchNumber);
      } else {
        return error('Unsupported search');
      }
      if(mySearchId !== lastSearchId) return;
      if(block) {
        setBlocks(blocks => [...blocks, block]);
      }

      // Fetch more backwards
      for(let i = 0; i < limit; i++) {
        if(!block) break;
        block = await getBlock(conf.oracles[0], block.parentHash);
        if(mySearchId !== lastSearchId) return;
        if(block) {
          setBlocks(blocks => [...blocks, block]);
          }
      }
    })();
  }, [searchHash, searchNumber, limit]);

  return (
    <div>
      {/* Filter block view */}
      <div>
        Hash: <input type="text" onChange={e => setSearchHash(e.target.value)} value={searchHash} placeholder="Search Hash" />
        Number: <input type="number" onChange={e => setSearchNumber(parseInt(e.target.value))} value={searchNumber} placeholder="Search Block Number" />
        Limit: <input type="number" onChange={e => setLimit(parseInt(e.target.value))} value={limit} placeholder="Fetch amount" />
        <button onClick={doSearch}>Fetch</button>
      </div>

      {/* Render block list */}
      <div>
        {blocks && <ObjectsExplorer objects={blocks} />}
      </div>
    </div>
  )
}