import Big from 'big.js';
import { parseUnits } from 'ethers/lib/utils';
import React from 'react';
import { useSession } from '../stores/sessionStore';
import { buf, hex } from '../../../oracle/src/lib/buffer';
import { fromChainAddress } from '../../../oracle/src/lib/chainAddress';
import { getAddress } from 'ethers/lib/utils';
import { Cropped } from './cropped';

export default function ChainAddress({ value, label }) {
  const [chainId, addr] = fromChainAddress(buf(value));
  const [connectedChainId] = useSession.chainId();
  if(label) label = `${label}: `

  return (
    <span>
      <Cropped>{label}{getAddress(hex(addr))}</Cropped> 
      {chainId && chainId !== BigInt(connectedChainId) && <span className="text-[0.7em] block">{chainId.toString()}</span>}
    </span>
  )
}