import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc';

dayjs.extend(duration);
dayjs.extend(utc);


export function OriginExpired({ expired, expiredDate }) {

  const [diffDays, setDiffDays] = useState<string>();
  const [diffHours, setDiffHours] = useState<string>();

  useEffect(() => {
    const interval = setInterval(() => {
      const a = dayjs.utc().format();
      const b = dayjs.utc(expiredDate);
      const diff = dayjs.duration(b.diff(a));

      setDiffDays(diff.format('DD'));
      setDiffHours(diff.format('HH:mm:ss'));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [expiredDate]);

  return <div className={`px-1 py-1 w-32 ${!diffDays || Number(diffDays) ? "" : "bg-red-200"} ml-1`}>{!expired ? <span>{diffDays} {diffDays ? "days" : ""} {diffHours}</span> : "expired"}</div>
}