import React, {
  useEffect, useMemo, useState,
} from 'react';

import { useDataStore } from '../../../stores/dataStore';
import { useFilteredData } from '../../../hooks/data';
import { useScanProofOfRecommendationOrigins } from '../../../oracles/proofOfRecommendations';

import { generateOriginsPerReferredToken } from './aggregation';

import  {Table } from './table';

const TAB = {
  ATTRACE: 'ATTRACE',
  MASK: 'MASK',
};
const REFERRER_ADDR = {
  ATTRACE: '0xbE1b7F0B02300B1667b88ece55903f4274C752bd',
  MASK: '0x172059839d80773eC8617C4CB33835175d364cEE',
};

export function Origins() {
  const [activeTab, setActiveTab] = useState<string>(TAB.ATTRACE);

  const [isLoaded] = useDataStore.isLoaded();
  const { farmExistsIdx } = useFilteredData();
  const {
    ensure: ensureOrigins, data: origins, error: errorOrigins,
  } = useScanProofOfRecommendationOrigins();

  useEffect(() => {
    ensureOrigins(true);
  }, []);

  const content = useMemo(
    () => {
      switch (activeTab) {
        case TAB.MASK:
          return <Table res={generateOriginsPerReferredToken(REFERRER_ADDR.MASK, origins, farmExistsIdx)} />;
        case TAB.ATTRACE:
        default:
          return <Table res={generateOriginsPerReferredToken(REFERRER_ADDR.ATTRACE, origins, farmExistsIdx)} />;
      }
    },
    [activeTab, origins, farmExistsIdx],
  );

  if (!isLoaded) return (<div><h4>Data loading...</h4></div>);

  return (
    <div>
      <div>
        <button
          className={`border-2 px-2 m-1 border bg-yellow-100 text-xs focus:outline-double ${activeTab === TAB.ATTRACE && 'outline-double'}`}
          onClick={(_) => setActiveTab(TAB.ATTRACE)}
        >
          Attrace
        </button>
        <button
          className={`border-2 px-2 m-1 border bg-yellow-100 text-xs focus:outline-double ${activeTab === TAB.MASK && 'outline-double'}`}
          onClick={(_) => setActiveTab(TAB.MASK)}
        >
          Mask
        </button>
      </div>
      <p style={{ margin: '10px 0'}}>
        {activeTab}
        {' '}
        referrer:
        {' '}
        <b>{REFERRER_ADDR[activeTab]}</b>
      </p>
      {content}
    </div>
  );
}
