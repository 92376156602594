import { trim, uniq } from "lodash";
import React from "react";
import { useUiContext } from "../stores/uiContextStore";

export default function ControlBar() {
  const [filterHashes, setFilterHashes] = useUiContext.filterHashes();
  
  const [showFarms, setShowFarms] = useUiContext.showFarms();
  const [showExplorer, setShowExplorer] = useUiContext.showExplorer();
  const [showTools, setShowTools] = useUiContext.showTools();
  const [showArch, setShowArch] = useUiContext.showArch();
  const [showBlocks, setShowBlocks] = useUiContext.showBlocks();
  const [showTransfers, setShowTransfers] = useUiContext.showTransfers();
  const [showDevtools, setShowDevtools] = useUiContext.showDevtools();

  function setFarms(str) {
    let parsed = str.split("\n");
    setFilterHashes(uniq(parsed.map(trim).filter(f => f.length > 0)));
  }

  function clearFilters() {
    setFilterHashes([]);
  }

  return (
    <div className="flex flex-auto">
      <div className="text-xs">
        <button className={`text-xs ${showFarms ? "styledButton" : "styledButton-inactive"}`} onClick={() => setShowFarms(c => !c)}>Farms</button>
        <button className={`text-xs ${showExplorer ? "styledButton" : "styledButton-inactive"}`} onClick={() => setShowExplorer(c => !c)}>Explorer</button>
        <button className={`text-xs ${showArch ? "styledButton" : "styledButton-inactive"}`} onClick={() => setShowArch(c => !c)}>Arch</button>
        <button className={`text-xs ${showTools ? "styledButton" : "styledButton-inactive"}`} onClick={() => setShowTools(c => !c)}>Tools</button>
        <button className={`text-xs ${showBlocks ? "styledButton" : "styledButton-inactive"}`} onClick={() => setShowBlocks(c => !c)}>Blocks</button>
        <button className={`text-xs ${showTransfers ? "styledButton" : "styledButton-inactive"}`} onClick={() => setShowTransfers(c => !c)}>Transfers</button>
        <button className={`text-xs ${showDevtools ? "styledButton" : "styledButton-inactive"}`} onClick={() => setShowDevtools(c => !c)}>DevTools</button>
      </div>
      <div className="flex ml-20 p-0 m-0">
        <div className="flex-grow p-0 m-0">
          <textarea placeholder="hashes" className="border w-96 h-8 text-xs pb-0 mb-0" value={filterHashes.join("\n")} onChange={e => setFarms(e.target.value)} />
        </div>
        <button className=" bg-gray-100 border-gray border hover:bg-gray-400 text-black pr-4 pl-4 text-xs mt-1 ml-2 h-6" onClick={_ => clearFilters()}>
          Clear filters
        </button>
      </div>
    </div>
  );
}