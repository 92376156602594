import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useUiContext } from "../stores/uiContextStore";
import stringify from 'json-stable-stringify';

let lastState, lastStateEncoded;

interface urlState {
  filterHashes: string[];
  showFarms: string[];
  showExplorer: string[];
}

export default function RouteState() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterHashes, setFilterHashes] = useUiContext.filterHashes();
  const [showFarms, setShowFarms] = useUiContext.showFarms();
  const [showExplorer, setShowExplorer] = useUiContext.showExplorer();

  function setNewState(d: urlState, changeState: boolean = true) {
    // Set the new state, so we don't loop
    const encoded = stringify(d);
    if(lastStateEncoded === encoded) return;
    lastStateEncoded = encoded;

    if(changeState) {
      // Set the props
      if(d.filterHashes) setFilterHashes(d.filterHashes);
      if(d.showFarms) setShowFarms(d.showFarms);
      if(d.showExplorer) setShowExplorer(d.showExplorer);
    }

    // Set the URL state
    searchParams.set('s', encoded);
    setSearchParams(searchParams, { replace: true });
  }

  useEffect(() => {
    const urlState = searchParams.get('s');
    
    // First load initialize
    if(urlState != null && lastStateEncoded == null) {
      setNewState(JSON.parse(urlState));
    }
  }, [searchParams]);

  // When filters change, we update the URL, so that it's always in sync
  useEffect(() => {
    const newState = {
      filterHashes,
      showExplorer,
      showFarms,
    }
    setNewState(newState, false);
  }, [filterHashes, showFarms, showExplorer]);

  return (<div></div>);
}