import React, { useEffect } from "react";
import { useFarmPositionJoined } from "../hooks/positions";
import { fmtTime } from "../lib/time";
import { Cropped } from "./cropped";
import TokenValue from "./tokenValue";

export default function FarmPositions({ farmHash, className }) {
  const { ensure, data: farmPositionJoined } = useFarmPositionJoined(farmHash);
  useEffect(ensure, []);

  return (
    <div className={`${className} table`}>
      <div className="table-row-group">
        {farmPositionJoined && farmPositionJoined.map((p, i) => {
          return (
            <div key={`joined-${i}`} className="table-row">
              <div className="ml-1 table-cell pr-2 text-xs"><TokenValue value={p.value} /></div>
              <div className="ml-1 table-cell font-mono text-xs"><Cropped>{p.holder}</Cropped></div>
              <div className="ml-1 table-cell font-mono text-xs"><Cropped>{p.secondary}</Cropped></div>
              <div className="ml-1 table-cell text-xs">{fmtTime(p.time)}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}