import Big from 'big.js';
import { useUiContext } from '../stores/uiContextStore';

export default function TokenValue({ value }): JSX.Element {
  const [units] = useUiContext.units();
  const [numberPrecision] = useUiContext.numberPrecision();
  return (
    <span>
      {value && Big(value).div(Big(10).pow(units)).toFixed(numberPrecision)}
    </span>
    // <div className="inline-grid truncate max-w-[70px] hover:overflow-visible">
    /* <span className="text-[0.7em] block">{value.toString()}</span> */
    // </div>
  )
}