import { defaultAbiCoder } from "@ethersproject/abi";
import { BigNumber } from "ethers";
import { buf, hex } from "../../../lib/buffer";
import { toChainAddress } from "../../../lib/chainAddress";
import { keccak256 } from "../../../lib/hash";
import { Buffer20, Buffer24, Buffer32 } from "../../../lib/types";
import { EntitlementInfo } from "../types";

export function makeLeafHash(chainId: bigint, confirmationsV1Addr: Buffer20, referralFarmsV1ChAddr: Buffer24, claim: EntitlementInfo) : Buffer32 {
  const leafHashStructure = defaultAbiCoder.encode(
    [
      'bytes24', // confirmationsAddr 
      'bytes24',  // referralFarmsAddr
      'address',  // msg.sender
      'bytes24', // rewardTokenDefn
      '(bytes32 farmHash, uint128 rewardValue, uint128 confirmation)' // entitlement
    ],
    [
      toChainAddress(chainId, confirmationsV1Addr), 
      referralFarmsV1ChAddr, 
      hex(claim.entitlee),
      claim.rewardToken,
      {
        farmHash: claim.farmHash,
        rewardValue: claim.rewardValue,
        confirmation: claim.confirmation,
      }
    ],
  )
  const leafHash = keccak256(buf(leafHashStructure));
  // console.log({ leafHash: hex(leafHash), claim });
  return leafHash;
}

export function makeDecreaseLeafHash(confirmationsV1ChAddr: Buffer24, referralFarmsV1ChAddr: Buffer24, farmHash: Buffer32, confirmation: bigint, value: bigint) : Buffer32 {
  const leafHashStructure = defaultAbiCoder.encode(
    [
      'bytes24', // confirmationsAddr 
      'bytes24',  // referralFarmsAddr
      'bytes32',  // farmHash
      'uint128', // confirmation
      'uint128' // value
    ],
    [
      confirmationsV1ChAddr, 
      referralFarmsV1ChAddr, 
      farmHash,
      confirmation,
      value,
    ],
  )
  const leafHash = keccak256(buf(leafHashStructure));
  // console.log({ leafHash: hex(leafHash), claim });
  return leafHash;
}

export function toFarmHashEthers(chainId: number, sponsor: string, rewardTokenDefn: string, referredTokenDefn: string) : string {
  return hex(keccak256(buf(defaultAbiCoder.encode(
    ['uint256', 'address','bytes24','bytes24'], 
    [BigNumber.from(chainId), sponsor, rewardTokenDefn, referredTokenDefn]
  ))));
}