import React from 'react';
import { useDataStore } from '../../../stores/dataStore';

import ObjectsExplorer from '../../objectsExplorer';

export function TableHeadRow({ cols }: { cols: string[] }) {
  return (
    <tr>
      {cols.map((col) => (
        <th
          className="border border-slate-200 italic text-xs"
          key={`th-${col}`}
        >
          {col}
        </th>
      ))}
    </tr>
  );
}

export function Table({ res }) {
  const [tokenList] = useDataStore.tokenList();

  return (
    <table>
      <thead>
        <TableHeadRow cols={['symbol', 'token', 'status', 'origins']} />
      </thead>
      <tbody>
        {res?.map((row, i) => {
          const tokenSymbol = tokenList?.get(row.token)?.symbol || ''
          const noOrigins = !row?.origins?.length;
          
          let valid = false;
          const tableData = row?.origins?.map(({ expired, ...rest }) => {
            if (!expired) valid = true;

            return rest
          })

          return (
            <tr
              className="border border-2 border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs"
              key={`tr-${row.token}-${i}`}
            >
              <td className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs">
                {tokenSymbol}
              </td>
              <td className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs">
                {row.token}
              </td>
              <td className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs">
                {noOrigins && <span className="px-1 py-1 bg-red-200 ml-1">❌</span>}
                {!noOrigins && <span className={`px-1 py-1 ${valid ? "bg-green-200" : "bg-red-200"} ml-1`}>🌞</span>}
              </td>
              <td className="border">
                {!noOrigins && (
                  <ObjectsExplorer
                    objects={tableData}
                    textFilterVisible={false}
                  />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}