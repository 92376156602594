import { useState } from 'react';
import useSWR from "swr/immutable";
import { JsonRpcResponse } from "../../../oracle/src/rpc/types";
import { rpcRequest, RpcRoute } from "./rpcHelpers";
import { error as reportError } from "../components/errorStack";

async function rpcRequestFetcher(route, method, ...params) : Promise<JsonRpcResponse> {
  return await rpcRequest(route, method, params);
}

export function useDerivedBlockHead() {
  const [shouldFetch, ensure] = useState(false);
  const { data, error } = useSWR(() => shouldFetch ? [RpcRoute.rpc, 'oracle_getDerivedBlockByNumber', 'latest'] : null, rpcRequestFetcher);

  if(error != null) {
    reportError(error);
  }

  return {
    ensure,
    data: data && data.header, 
    error,
  }
}