import { Contract, ethers, Event } from "ethers";
import { Hex32 } from "../../../oracle/src/lib/types";
import { getProvider } from "../helpers";
import { getSession } from "../stores/sessionStore";

const abi = [
  `function getHead() view returns (bytes32)`,
  `function getConfirmation(bytes32 confirmationHash) view returns(uint128 number, uint64 timestamp)`,
  `event ConfirmationFinalized(bytes32 indexed confirmationHash, uint128 indexed number, bytes32 stateRoot, bytes32 parentHash, uint64 timestamp, bytes32 bundleHash, bytes32 indexed closerHash, uint32 blockCount, bytes32 blockHash, uint64 confirmChainBlockNr)`,
  `function finalize(bytes32 confirmationHash, uint128 number, bytes32 stateRoot, bytes32 parentHash, uint64 timestamp, bytes32 bundleHash, bytes32 closerHash, uint32 blockCount, bytes32 blockHash, uint64 confirmChainBlockNr)`
]

export const confirmationV1Iface = new ethers.utils.Interface(abi);

export function getConfirmationsV1() : Contract {
  const [addr] = getSession.confirmations();
  return new ethers.Contract(addr, confirmationV1Iface, getProvider());
}

export interface ConfirmationFinalizedEvent {
  confirmationHash: Hex32;
  number: number;
  stateRoot: Hex32;
  parentHash: Hex32;
  timestamp: number;
  bundleHash: Hex32;
  closerHash: Hex32;
  blockCount: number;
  blockHash: Hex32;
  confirmChainBlockNr: number;
  event: Event;
}

export async function getConfirmationFinalizedEvents() : Promise<ConfirmationFinalizedEvent[]> {
  const contract = getConfirmationsV1();  
  const events = await contract.queryFilter(contract.filters.ConfirmationFinalized());
  return events.map(e => {
    return {
      confirmationHash: e.args.confirmationHash,
      number: e.args.number.toNumber(),
      stateRoot: e.args.stateRoot,
      parentHash: e.args.parentHash,
      timestamp: e.args.timestamp.toNumber(),
      bundleHash: e.args.bundleHash,
      closerHash: e.args.closerHash,
      blockCount: e.args.blockCount,
      blockHash: e.args.blockHash,
      confirmChainBlockNr: e.args.confirmChainBlockNr.toNumber(),
      event: e,
    }
  });
}

export async function finalize(props, contractAddr) {
  console.log("Selected confirmation:", { props });

  try {
    const provider = await new ethers.providers.Web3Provider(window.ethereum);
    const sponsor = provider.getSigner();

    const confirmationsContract = new ethers.Contract(
        contractAddr,
        abi,
        provider
    );

    const tx = await confirmationsContract
        .connect(sponsor)
        .finalize(...Object.values(props));
    const txDetails = await tx.wait();

    return txDetails;
  } catch (e) {
    console.log(e.message);
    return `Error: ${e.message}`;
  }
}