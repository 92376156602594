import { useEffect, useMemo } from 'react';
import { useScanProofOfRecommendationOrigins, useScanProofOfRecommendations } from '../../../../oracles/proofOfRecommendations';
import { useDataStore } from '../../../../stores/dataStore';
import { useUiContext } from '../../../../stores/uiContextStore';
import { useFarmPositionDecreasedWithConfirmationEvents, useFarmPositionIncreasedWithConfirmationEvents } from '../../../utils/aggregation';
import { stringer } from '../../../utils/stringer';

import { generateMetricRows } from './aggregation';
import { useFetchVisitors } from "../hooks/useFetchVisitors"

const REFERRED_TOKEN_METRICS_HEADERS = ['Symbol', 'Referred Token', "Promoter", 'Buy to Earn visitors #', 'Recommendation #', 'Buyer with positions #',  "Buyers", 'Tokens bought', 'Current position'];

export default function PromoterMetricsView({ textFilter }) {
  const [isLoaded] = useDataStore.isLoaded();
  const [tokenList] = useDataStore.tokenList();

  const [numberPrecision] = useUiContext.numberPrecision();
  const [units] = useUiContext.units();

  const { ensure: ensureRecommendations, data: recommendations } = useScanProofOfRecommendations();
  const { ensure: ensureRecommendationOrigins, data: recommendationOrigins } = useScanProofOfRecommendationOrigins();
  const { ensure: ensureFarmPositionIncreased, data: farmPositionIncreasedEvents } = useFarmPositionIncreasedWithConfirmationEvents();
  const { ensure: ensureFarmPositionDecreased, data: farmPositionDecreasedEvents } = useFarmPositionDecreasedWithConfirmationEvents();
  const { data: visitors } = useFetchVisitors()

  // get data to generate metrics
  useEffect(() => {
    ensureRecommendations(true);
    ensureRecommendationOrigins(true);
    ensureFarmPositionIncreased();
    ensureFarmPositionDecreased();
  }, []);

  if (!isLoaded) return (<div><h4>Metrics loading...</h4></div>);

  const rows = useMemo(() => {
    if (!recommendationOrigins?.length) return [];

    let rows = generateMetricRows({
      recommendationOrigins,
      recommendations,
      farmPositionIncreasedEvents,
      farmPositionDecreasedEvents,
      visitors,
      units,
      numberPrecision,
      tokenList
    });

    if(textFilter?.length > 1) {
      const fltr = textFilter.toLowerCase();
      rows = rows.filter(row => stringer(row).indexOf(fltr) >= 0);
    }
    
    return rows
  }, [recommendationOrigins, recommendations, farmPositionIncreasedEvents, textFilter]);

  return (
    <div>
      <table className="table-auto border-collapse border border-slate-500">
        <thead>
          <tr key="promoterMetric-header-tr">
            {REFERRED_TOKEN_METRICS_HEADERS.map((h) => (
              <th
                key={`promoterMetric-header-${h}`}
                className="border border-slate-200 italic text-xs"
              >
                {h}
              </th>
            )) }
          </tr>
        </thead>
        <tbody>
          {rows.map((r, i) => (
            <tr key={`promoterMetric-td-tr-${i}`}>
              <td
                  key={`promoterMetric-row-${i}-symbol`}
                  className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs"
              >
                {r.symbol}
              </td>
             <td
                key={`promoterMetric-row-${i}-referredToken`}
                className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs"
              >
                {r.referredToken}
              </td>
              <td
                key={`promoterMetric-row-${i}-promoter`}
                className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs"
              >
                {r.promoter}
              </td>
              <td
                key={`promoterMetric-row-${i}-buyerPageVisitorCount`}
                className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs"
              >
                {r.buyerPageVisitorCount}
              </td>
              <td
                key={`promoterMetric-row-${i}-recommendationCount`}
                className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs"
              >
                {r.recommendationCount}
              </td>
              <td
                key={`promoterMetric-row-${i}-buyerWithPositionCount`}
                className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs"
              >
                {r.buyerWithPositionCount}
              </td>
              <td
                key={`promoterMetric-row-${i}-buyers`}
                className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs"
              >
                {JSON.stringify(r.buyers)}
              </td>
              <td
                key={`promoterMetric-row-${i}-totalAmount`}
                className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs text-right"
              >
                {r.totalAmount}
              </td>
              <td
                key={`promoterMetric-row-${i}-currentPosition`}
                className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs text-right"
              >
                {r.currentPosition}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
