import { FormatTypes, Interface } from "@ethersproject/abi";
import { ethers, Event } from "ethers";
import { hex } from "../../../oracle/src/lib/buffer";
import { Hex20, Hex32 } from "../../../oracle/src/lib/types";
import { toReactorAddress } from "../../../oracle/src/rpc/lib/address";
import { rpcRequest, RpcRoute } from "./rpcHelpers";
import { error } from "../components/errorStack";
import { useEffect, useState } from "react";

const iface = new Interface([
  `event Entitlement(bytes32 indexed farmHash, address indexed entitlee, uint128 confirmation, uint128 rewardValue, bytes32[] proof)`,
  `event LockedEntitlement(bytes32 indexed farmHash, address indexed entitlee, uint128 confirmation, uint128 rewardValue, uint64 unlockTime)`,
]);

export interface EntitlementEvent {
  farmHash: Hex32;
  entitlee: Hex20;
  confirmation: number;
  rewardValue: bigint;
  proof: Hex32[];
  event: Event;
  log: any;
}

export async function getEntitlementEvents(filterHashes?: string[]): Promise<EntitlementEvent[]> {
  let topics = [
    ethers.utils.id(iface.getEvent('Entitlement').format(FormatTypes.sighash)),
  ];


  if (filterHashes?.length) {
    // hotfix to avoid the exceeded the quota, get entitlements only for one farmHash
    topics.push(filterHashes[0]);
  }

  const params = [{
    address: hex(toReactorAddress('entitlementsV1Reactor')),
    topics,
  }];
  const logs = await rpcRequest(RpcRoute.rpc, 'oracle_getLogs', params);
  const events: EntitlementEvent[] = [];

  // Parse events
  for(let log of logs) {
    const event = iface.parseLog(log);
    events.push({
      farmHash: event.args.farmHash,
      entitlee: event.args.entitlee,
      confirmation: event.args.confirmation.toNumber(),
      rewardValue: event.args.rewardValue.toBigInt(),
      proof: event.args.proof,
      event,
      log,
    });
  }

  return events;
}


export interface LockedEntitlementEvent {
  farmHash: Hex32;
  entitlee: Hex20;
  confirmation: number;
  rewardValue: bigint;
  proof: Hex32[];
  event: Event;
  log: any;
}

export async function getLockedEntitlementEvents() : Promise<LockedEntitlementEvent[]> {
  const params = [{
    address: hex(toReactorAddress('entitlementsV1Reactor')),
    topics: [
      ethers.utils.id(iface.getEvent('LockedEntitlement').format(FormatTypes.sighash)),
    ],
  }];
  const logs = await rpcRequest(RpcRoute.rpc, 'oracle_getLogs', params);
  const events: LockedEntitlementEvent[] = [];

  // Parse events
  for(let log of logs) {
    const event = iface.parseLog(log);
    events.push({
      farmHash: event.args.farmHash,
      entitlee: event.args.entitlee,
      confirmation: event.args.confirmation.toNumber(),
      rewardValue: event.args.rewardValue.toBigInt(),
      unlockTime: event.args.unlockTime.toNumber(),
      event,
      log,
    });
  }

  return events;
}

export function useLockedEntitlementEvents() : LockedEntitlementEvent[] {
  const [lockedEntitlements, setLockedEntitlements] = useState<LockedEntitlementEvent[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const events = await getLockedEntitlementEvents()
        setLockedEntitlements(events);
      } catch (err) {
        error(err);
      }
    })();
  }, []);

  return lockedEntitlements;
}