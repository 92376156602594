import React from "react";
import HumanAbiEval from "./humanAbiEval";
import HumanAbiEvalOracle from "./humanAbiEvalOracle";

export default function Tools() {
  return (
    <div>
      <div>
        Query connected evm network:
        <HumanAbiEval />
      </div>

      <div>
        Query connected oracle:
        <HumanAbiEvalOracle />
      </div>
    </div>
  )
}