import React, { useState, useEffect } from "react";

import { groupBy } from 'lodash';

import ConfirmationV1 from './confirmationV1';
import DeveloperSupportV1 from './developerSupportV1';
import { ReferredTokenMetrics } from "./metrics/referredTokenMetrics";
import { PromoterMetrics } from "./metrics/promoterMetrics";
import { Origins } from './origins';

import { useDataStore } from "../../stores/dataStore";
import {ERC20Token, TokenListMap, useFetchTokenList} from "./metrics/hooks/useFetchTokenList";
import {WalletProvider} from "./providers/walletProvider";

export default function DevTools() {
  const [, setTokenList] = useDataStore.tokenList();

  const [showConfirmationV1, setShowConfirmationV1] = useState(false);
  const [showDeveloperSupportV1, setShowDeveloperSupportV1] = useState(false);
  const [showReferredTokenMetrics, setShowReferredTokenMetrics] = useState(false);
  const [showPtomoterMetrics, setShowPtomoterMetrics] = useState(false);
  const [showOrigins, setShowOrigins] = useState(false);

  const [textFilter, setTextFilter] = useState('');

  const { data } = useFetchTokenList();

  useEffect(() => {
      if (data) {
          const tokenListSorted = data
              .flatMap((list) => list.tokens);
          const groupedTokens = groupBy(tokenListSorted, (token: ERC20Token) => token.address.toLowerCase());
          const fullList: TokenListMap = new Map(Object.entries(groupedTokens)
              .flatMap(([, value]) => value.reduce((r, c) => Object.assign(r, c), {} as ERC20Token))
              .map((token) => [token.address.toLowerCase(), token]));

          setTokenList(fullList);
      }

  }, [data]);

  return (
    <div className="mt-4 border-t-2 border-black">
      <h1>DevTools</h1>

      <div className="flex flex-col border-t border-gray-200">
        <div>
          <button className="border-1 px-2 m-1 border bg-yellow-100 border-green-900 text-xs focus:outline-double" onClick={_ => setShowConfirmationV1(c => !c)}>ConfirmationV1</button>
          <button className="border-1 px-2 m-1 border bg-yellow-100 border-green-900 text-xs focus:outline-double" onClick={_ => setShowDeveloperSupportV1(c => !c)}>DeveloperSupportV1</button>
          <button className="border-1 px-2 m-1 border bg-sky-100 border-blue-900 text-xs focus:outline-double" onClick={_ => setShowOrigins(c => !c)}>Origins</button>
          <button className="border-1 px-2 m-1 border bg-red-100 border-blue-900 text-xs focus:outline-double" onClick={_ => setShowReferredTokenMetrics(c => !c)}>Metrics(referred token)</button>
          <button className="border-1 px-2 m-1 border bg-red-100 border-blue-900 text-xs focus:outline-double" onClick={_ => setShowPtomoterMetrics(c => !c)}>Metrics(promoter)</button>
        </div>
      <div className="flex border-b border-gray-200 pb-2 mb-2">
        <div className="flex flex-wrap ml-1">
          <input type="text" className="border text-xs" style={{width: '400px'}} value={textFilter} onChange={e => setTextFilter(e.target.value)} />
        </div>
      </div>
        <div>
            <WalletProvider>
                <>
                  {showConfirmationV1 && <ConfirmationV1 />}
                  {showDeveloperSupportV1 && <DeveloperSupportV1 />}
                  {showOrigins && <Origins />}
                  {showReferredTokenMetrics && <ReferredTokenMetrics textFilter={textFilter} />}
                  {showPtomoterMetrics && <PromoterMetrics textFilter={textFilter} />}
                </>
            </WalletProvider>
        </div>
      </div>
    </div>
  );
}