import { useState } from "react";

import { requestConfirmation } from "../../../contracts/developerSupportV1";
import {useWalletContext} from "../providers/walletProvider";


function DeveloperSupportV1() {
    const { userAddress, connect } = useWalletContext();

    const [result, setResult] = useState<string | undefined>();

    const onClickConfirm = async () => {
        const res = await requestConfirmation();
        setResult(res);
    };

    return (
        <>
            <div className="flex-column">
                <p>
                    <span className="error"> <i>Important:</i></span>
                    {" "}use developer account <strong>0xE22CCe03866D4B636bBb48A51DaD08872e64C6f6</strong> on testnet.
                </p>
            </div>
            {userAddress ? (
                <div className="flex-column">
                    <p>
                        Account: <strong>{userAddress}</strong>
                    </p>
                    <div className="flex-column">
                        <button
                            className="bg-stone-300 border-2 border-black"
                            onClick={onClickConfirm}
                            style={{ margin: "12px 0", maxWidth: 200 }}
                        >
                            Confirm next block
                        </button>
                        {result && JSON.stringify(result)}
                    </div>
                </div>
            ) : (
                <button
                    className="bg-stone-300 border-2 border-black"
                    style={{ margin: "12px 0" }}
                    onClick={connect}
                >
                    Connect wallet
                </button>
            )}
        </>
    );
}

export default DeveloperSupportV1;
