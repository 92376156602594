import { getAddress } from "ethers/lib/utils";
import { uniq } from "lodash";
import { useEffect, useState } from "react";
import { buf, hex } from "../../../../oracle/src/lib/buffer";
import { fromChainAddress } from "../../../../oracle/src/lib/chainAddress";
import { Hex20, Hex32 } from "../../../../oracle/src/lib/types";
import { useFilteredData } from "../../hooks/data";
import { useFarmTokenInfo } from "../../hooks/farms";
import { fmtTime, sleep } from "../../lib/time";
import { useScanProofOfRecommendations } from "../../oracles/proofOfRecommendations";
import { useFarmsTrackedRewardValue } from "../../oracles/referralFarmsV1";
import { useSession } from "../../stores/sessionStore";
import ChainAddress from "../chainAddress";
import { error } from "../errorStack";
import SelectableColumn from "../selectableColumn";
import TokenValue from "../tokenValue";
import { useFarmPositionDecreasedWithConfirmationEvents, useFarmPositionIncreasedWithConfirmationEvents } from "../utils/aggregation";
import { EtherscanTransferEvent, FarmPositionChangeEventWithConfirmation, ParticipantPositionsTable, ParticipantPositionViewModel, TableColumnDefinition } from "./participantPositionsTable";

const defaultOracleEventCols = [
  'farmHash',
  'token',
  'holder',
  'value',
  'time',
  'fmtTime',
  'origin',
  'tx',
  'logIdx',
  'event',
  'log',
  'blockNr',
  'confirmationNr',
]

const defaultOracleEventTableFields: TableColumnDefinition = new Map();
defaultOracleEventTableFields['farmHash'] = true
defaultOracleEventTableFields['token'] = true
defaultOracleEventTableFields['holder'] = true
defaultOracleEventTableFields['value'] = true
defaultOracleEventTableFields['time'] = true
defaultOracleEventTableFields['fmtTime'] = true
defaultOracleEventTableFields['origin'] = true
defaultOracleEventTableFields['tx'] = false
defaultOracleEventTableFields['logIdx'] = false
defaultOracleEventTableFields['event'] = false
defaultOracleEventTableFields['log'] = false
defaultOracleEventTableFields['blockNr'] = false
defaultOracleEventTableFields['confirmationNr'] = false

const defaultERC20EventCols = [
  'blockHash',
  'blockNumber',
  'confirmations',
  'contractAddress',
  'cumulativeGasUsed',
  'from',
  'gas',
  'gasPrice',
  'gasUsed',
  'hash',
  'input',
  'nonce',
  'timeStamp',
  'fmtTime',
  'to',
  'tokenDecimal',
  'tokenName',
  'tokenSymbol',
  'transactionIndex',
  'value',
]
const defaultERC20EventTableFields: TableColumnDefinition = new Map();
defaultERC20EventTableFields['blockHash'] = false
defaultERC20EventTableFields['blockNumber'] = false
defaultERC20EventTableFields['confirmations'] = false
defaultERC20EventTableFields['contractAddress'] = true
defaultERC20EventTableFields['cumulativeGasUsed'] = false
defaultERC20EventTableFields['from'] = true
defaultERC20EventTableFields['gas'] = false
defaultERC20EventTableFields['gasPrice'] = false
defaultERC20EventTableFields['gasUsed'] = false
defaultERC20EventTableFields['hash'] = false
defaultERC20EventTableFields['input'] = false
defaultERC20EventTableFields['nonce'] = false
defaultERC20EventTableFields['timeStamp'] = true
defaultERC20EventTableFields['fmtTime'] = true
defaultERC20EventTableFields['to'] = true
defaultERC20EventTableFields['tokenDecimal'] = false
defaultERC20EventTableFields['tokenName'] = true
defaultERC20EventTableFields['tokenSymbol'] = true
defaultERC20EventTableFields['transactionIndex'] = false
defaultERC20EventTableFields['value'] = true

export default function FarmPositionsView({farmHash, chainId}: 
  {
    farmHash: Hex32,
    chainId: number,
  }) {
  const { ensure: ensureRecommendations, data: recommendations } = useScanProofOfRecommendations();
  const { farmExists } = useFilteredData();
  const { data: farmTrackedRewardValue, ensure: ensureFarmsTrackedRewardValue } = useFarmsTrackedRewardValue([farmHash])
  const { referredTokenDefn, rewardTokenDefn } = useFarmTokenInfo(farmHash);
  const { ensure: ensureFarmPositionIncreased, data: farmPositionIncreasedEvents } = useFarmPositionIncreasedWithConfirmationEvents();
  const { ensure: ensureFarmPositionDecreased, data: farmPositionDecreasedEvents } = useFarmPositionDecreasedWithConfirmationEvents();
  const [networkConfigs] = useSession.networks()

  const [oracleEventCols, setOracleEventCols] = useState<string[]>(defaultOracleEventCols);
  const [oracleEventFields, setOracleEventFields] = useState<TableColumnDefinition>(defaultOracleEventTableFields);
  const [erc20EventCols, setErc20EventCols] = useState<string[]>(defaultERC20EventCols);
  const [erc20EventFields, setErc20EventFields] = useState<TableColumnDefinition>(defaultERC20EventTableFields);
  const [erc20Filter, setErc20Filter] = useState<string>('');

  const [participantPositionViewModels, setParticipantPositionViewModels] = useState<ParticipantPositionViewModel[]>([]);
  const [filteredParticipantPositionViewModels, setFilteredParticipantPositionViewModels] = useState<ParticipantPositionViewModel[]>([]);

  // Init
  useEffect(() => {
    ensureRecommendations(true)
    ensureFarmsTrackedRewardValue()
    ensureFarmPositionIncreased()
    ensureFarmPositionDecreased()
  }, [])

  // Get buyers list
  useEffect(() => {
    if (!recommendations) return;

    // console.log('recommendations changed', recommendations?.length)
    const participantAddrs = uniq(recommendations?.map(r => r.signer) || [])
    // setBuyerAddrs(addrs)

    const positionsViewModels: ParticipantPositionViewModel[] = []
    // Get oracle positions based on participant addresses
    const allPositionChangeEvents: FarmPositionChangeEventWithConfirmation[] = [...farmPositionIncreasedEvents, ...farmPositionDecreasedEvents]
    for (const participantAddr of participantAddrs) {
      const events = allPositionChangeEvents.filter(e => {
        return e.holder.toString().toLowerCase() == participantAddr.toString().toLowerCase() &&
          e.farmHash == farmHash
      }).sort((a, b) => -(a.time - b.time)) // DESC
      positionsViewModels.push({
        address: participantAddr,
        oraclePositionChangedEvents: events,
        etherscanTransferEvents: [],
      })
    }
    setParticipantPositionViewModels(positionsViewModels)

    // Get events from oracles, non blocking call, don't need await
    startGetERC20TransfersInBackGround(positionsViewModels, participantAddrs)
  }, [recommendations])

  // filter erc 20 transfer by addresses
  useEffect(() => {
    if (!erc20Filter || !erc20Filter.length) {
      // console.log('filteredParticipantPositionViewModels just clone', participantPositionViewModels)
      setFilteredParticipantPositionViewModels(cloneParticipantPositionViewModels(participantPositionViewModels))
      return
    }

    if (participantPositionViewModels && participantPositionViewModels.length) {
      const lowercaseFilterMap = new Map<string, boolean>()
      erc20Filter.toLowerCase().split(',').forEach(f => lowercaseFilterMap[f] = true)
      const cloneModels = cloneParticipantPositionViewModels(participantPositionViewModels)
      for (const m of cloneModels) {
        m.etherscanTransferEvents = m.etherscanTransferEvents.filter(e => {
          // This seems to check if the "other party" of the transfer is in the list of filtered addresses
          const otherAddr = e.from.toLowerCase() == m.address.toString().toLowerCase() ? e.to : e.from;
          return lowercaseFilterMap[otherAddr.toString().toLowerCase()]
        })
      }

      // console.log('filtered', cloneModels)
      setFilteredParticipantPositionViewModels(cloneModels)
    }
  }, [erc20Filter, participantPositionViewModels])

  function cloneParticipantPositionViewModels(participantPositionViewModels: ParticipantPositionViewModel[]) {
    return participantPositionViewModels.map(m => ({ 
      ...m,
      etherscanTransferEvents: m.etherscanTransferEvents.map(e => ({ 
        ...e,
      })),
      oraclePositionChangedEvents: m.oraclePositionChangedEvents.map(e => ({
        ...e,
        event: {...e.event},
      })),
    }))
  }

  const startGetERC20TransfersInBackGround = async (viewModels: ParticipantPositionViewModel[], participantAddrs: Hex20[]) => {
    const [_, referredTokenBuf] =  fromChainAddress(buf(referredTokenDefn))
    const referredTokenAddr = getAddress(hex(referredTokenBuf))

    const farmExistEvents = farmExists.filter(e => e.farmHash.toString().toLowerCase() == farmHash.toString().toLowerCase())
    // console.log('startGetERC20TransfersInBackGround', farmExistEvents)
    const startBlock = farmExistEvents.length > 0 ? farmExistEvents[0].event.blockNumber : undefined

    try {
      for (const p of participantAddrs) {
        const transfers = await getEtherscanERC20Transfers({
          addr: p.toString(),
          tokenAddr: referredTokenAddr,
          startBlock: startBlock,
          sort: 'desc'
        })
  
        // const viewModels = cloneViewModels()
        viewModels.filter(v => v.address.toString().toLowerCase() == p.toString().toLowerCase())
          .forEach(m => m.etherscanTransferEvents = transfers)
        setParticipantPositionViewModels(cloneParticipantPositionViewModels(viewModels))
  
        await sleep(250) // Avoiding too many requests
      }
    } catch (err) {
      error(err)
    }
  }

  const getEtherscanERC20Transfers = async ({ addr, tokenAddr, page, offset, startBlock, endBlock, sort }: {
    addr: string
    tokenAddr?: string
    page?: number
    offset?: number
    startBlock?: number
    endBlock?: number
    sort?: string
  }): Promise<EtherscanTransferEvent[]> => {
    const apiURL = networkConfigs[chainId].etherscanApiURL
    console.log('apiURL', apiURL)
    if (!apiURL) return [] // Not supported yet. Don't search
    
    const urlWithParams = new URL(apiURL)
    urlWithParams.searchParams.append('module', 'account')
    urlWithParams.searchParams.append('action', 'tokentx')
    if (addr && addr.length) urlWithParams.searchParams.append('address', addr);
    
    tokenAddr && urlWithParams.searchParams.append('contractaddress', tokenAddr)
    page && urlWithParams.searchParams.append('page', page.toString())
    offset && urlWithParams.searchParams.append('offset', offset.toString())
    startBlock && urlWithParams.searchParams.append('startBlock', startBlock.toString())
    endBlock && urlWithParams.searchParams.append('endBlock', endBlock.toString())
    sort && urlWithParams.searchParams.append('sort', sort.toString())
    // TODO: belongs in network definition
    urlWithParams.searchParams.append('apikey', networkConfigs[chainId].etherscanAPIKey || '')

    // console.log('urlWithParams', urlWithParams.href)
    const req = await fetch(urlWithParams.href, {
      method: 'GET',
    })
    if (req.status != 200) {
      console.warn(req);
      throw new Error('request failed: '+req.statusText)
    }
    const res = await req.json()

    const rows = res.result || []
    return rows.map(e => ({...e, fmtTime: fmtTime(Number(e.timeStamp))}))
  }

  return (
    <div key={farmHash.toString()}>
      <h3>Farm {farmHash.toString()}</h3>

      <div><ChainAddress value={referredTokenDefn} label="referredToken" /></div>
      <div><ChainAddress value={rewardTokenDefn} label="rewardToken" /></div>
      {farmTrackedRewardValue && <div>depositRemaining: <TokenValue value={farmTrackedRewardValue[0]?.reward} /></div>}

      {/* Toggle oracle event cols */}
      <div>
        <span>Oracle Event Cols: </span>
        {oracleEventCols.map(col => <SelectableColumn
          key={`sel-${col}`} 
          name={col} 
          value={oracleEventFields[col] || false} 
          // onClick={_ => setLastCol(col)}
          onToggle={status => setOracleEventFields(p => ({...p, [col]: status}))} />)}
      </div>


      {/* Toggle ERC event cols */}
      <div>
      <span>ERC20 Event Cols: </span>
        {erc20EventCols.map(col => <SelectableColumn
          key={`sel-${col}`} 
          name={col} 
          value={erc20EventFields[col] || false} 
          // onClick={_ => setLastCol(col)}
          onToggle={status => setErc20EventFields(p => ({...p, [col]: status}))} />)}
      </div>

      {/* ERC20 from/to 's filter */}
      <div className="mt-5">
        <label className="w-96">Other party of the ERC20 transfer which is not the participant</label>
        <input className="border text-xs ml-5 w-96 h-8" type="text" onChange={e => setErc20Filter(e.target.value)} placeholder='Addresses. Comma separated'></input>
      </div>

      {/* Positions */}
      {filteredParticipantPositionViewModels.map((p, i) => <ParticipantPositionsTable key={`${p.address.toString()}-${i}`} model={p} 
        oracleEventCols={oracleEventCols} oracleEventFields={oracleEventFields}
        etherscanTransferEventCols={erc20EventCols} etherscanTransferEventFields={erc20EventFields} />)}
    </div>
  );
}

