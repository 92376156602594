import { ethers } from "ethers";
import { trim } from "lodash";
import React, { useState } from "react";
import { useCallback } from "react";
import { getProvider } from "../helpers";

export default function HumanAbiEval() {
  const addrLabel = 'addr, eg: 0xaddr';
  const funcLabel = `function, eg: function token0() view returns (address)`;
  const argsLabel = `args in json, eg: []`;

  const [addr, setAddr] = useState('0xc778417e063141139fce010982780140aa0cd5ab');
  const [func, setFunc] = useState('function token0() view returns (address)');
  const [args, setArgs] = useState('[]');
  const [output, setOutput] = useState('');

  const execute = useCallback(() => {
    setOutput('');
    (async () => {
      try {
        const iface = new ethers.utils.Interface([ func ]);
        const contract = new ethers.Contract(addr, iface, getProvider());
        const parsed = JSON.parse(args);
        const res = await contract.functions[Object.keys(iface.functions)[0]](...parsed);
        console.log(res);
        setOutput(JSON.stringify(res));
      } catch(err) {
        console.error(err);
        setOutput('error see console '+err.toString());
      }
    })();
  }, [addr, func, args]);

  return (
    <div>
      open console for output or improve tool
      <div>
        <label>{addrLabel}</label><br />
        <input className="border w-96 h-8 text-xs pb-0 mb-0" type="text" value={addr} onChange={e => setAddr(e.target.value)} placeholder={addrLabel} />
      </div>
      <div>
        <label>{funcLabel}</label><br />
        <textarea className="border w-96 h-8 text-xs pb-0 mb-0" value={func} onChange={e => setFunc(e.target.value)} placeholder={funcLabel} />
      </div>
      <div>
        <label>{argsLabel}</label><br />
        <textarea className="border w-96 h-8 text-xs pb-0 mb-0" value={args} onChange={e => setArgs(e.target.value)} placeholder={argsLabel} />
      </div>
      <pre>{output}</pre>
      <button onClick={() => execute()}>run</button>
    </div>
  )
}