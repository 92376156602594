import { useEffect, useState } from "react";

export default function SelectableColumn({ value, name, onToggle }) {
  const [isActive, setIsActive] = useState(value || false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, [])

  useEffect(() => {
    if(loaded) {
      onToggle && onToggle(isActive);
    }
  }, [isActive]);

  return (
    <button 
      className={`border-1 px-2 m-1 border border-gray-200 bg-gray-100 italic text-xs ${isActive ? 'font-bold outline ' : ''}`} 
      // onClick={onClick || undefined} 
      onClick={() => setIsActive(p => !p)}>{name}</button>
  );
}