import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { sortBy } from "lodash";
import React, { useEffect } from "react";
import { fmtTime } from "../lib/time";
import { getSession, setSession, useSession } from "../stores/sessionStore";
import { TraceableError } from "../types";

export function error(...props: any[]) {
  const err = toTraceableError(...props);
  err.level = 'ERROR';
  stackError(err);
}

export function warn(...props: any[]) {
  const err = toTraceableError(...props);
  err.level = 'WARN';
  stackError(err);
}

function stackError(err: TraceableError) {
  const [errors, setErrors] = getSession.errors();
  console.error(err);
  if(err.err) {
    console.trace(err.err);
  }
  setErrors(errors => [...errors, err]);
}

export function toTraceableError(...props: any[]) : TraceableError {
  const e: TraceableError = {
    id: crypto.randomUUID(),
    time: Date.now(),
    props: [],
  };
  for(let p of props) {
    if(p instanceof Error) {
      e.err = p; 
    } else {
      e.props.push(p);
    }
  }
  return e;
}

// Janitor
setInterval(() => {
  const [errors, setErrors] = getSession.errors();
  if(errors.length == 0) return;
  setErrors(errors => errors.filter(err => err.time > Date.now() - 5000))
}, 1000);

export default function ErrorStack() {
  const [errors] = useSession.errors();
  return (
    <div className="fixed bottom-1 right-1">
      {errors && errors.map(e => {
        let parts = [e.props|[]].map(p => JSON.stringify(p));
        if(e.err) {
          parts.push(e.err.message)
        }

        return (
          <div key={e.id} className="bg-red-500">
            {dayjs.utc(e.time).format('mm:ss')} {parts.join(' ')}
          </div>
        )
      })}
    </div>
  )
}