import { BigNumber } from "ethers";
import { solidityPack } from "ethers/lib/utils";
import { useEffect, useState } from "react";
import { makeLeafHash } from '../../../oracle/src/deriver/reactors/lib/hash';
import { buf, hex } from "../../../oracle/src/lib/buffer";
import { keccak256 } from "../../../oracle/src/lib/hash";
import { useFilteredData } from "../hooks/data";
import { EntitlementEvent } from "../oracles/entitlementsV1";
import { useSession } from "../stores/sessionStore";

export default function ProofValidate({ event } : { event: EntitlementEvent }) {
  const [chainId] = useSession.chainId();
  const [confirmationsAddr] = useSession.confirmations();
  const [farmsAddr] = useSession.farms();
  const [rewardToken, setRewardToken] = useState(null);
  const [leafHashUi, setLeafHash] = useState(null);
  const [computedHashUi, setComputedHash] = useState(null);
  const [confirmationUi, setConfirmation] = useState(null);
  const [confirmationFinalizedUi, setConfirmationFinalized] = useState(null);
  const [rewardsHarvestedUi, setRewardsHarvested] = useState(null);
  const { farmExistsIdx, confirmationIdx, confirmationFinalizedIdx, rewardsHarvestedIdx } = useFilteredData();

  // Resolve the rewardToken from the farmHash

  useEffect(() => {
    if(!event || !farmExistsIdx) return;
    // Resolve reward token for farm
    const fe = farmExistsIdx[event.farmHash];
    setRewardToken(fe && fe.rewardTokenDefn || 'farm not found');
  }, [event, chainId, farmExistsIdx]);

  useEffect(() => {
    if(!rewardToken || !confirmationIdx || !confirmationFinalizedIdx || !rewardsHarvestedIdx) return;

    const leafHash = makeLeafHash(
      BigInt(chainId), 
      buf(confirmationsAddr), 
      buf(farmsAddr), 
      {
        confirmation: BigInt(event.confirmation),
        entitlee: buf(event.entitlee),
        farmHash: buf(event.farmHash),
        rewardValue: event.rewardValue,
        rewardToken: buf(rewardToken),
      },
    );

    // When we have the leafHash, we can compute the root
    let computedHash = hex(leafHash);
    for (let i = 0; i < event.proof.length; i++) {
      let proofElement = event.proof[i];
      if(BigNumber.from(computedHash).lte(BigNumber.from(proofElement))) {
        // Hash(current computed hash + current element of the proof)
        computedHash = hex(keccak256(buf(solidityPack(['bytes32', 'bytes32'], [computedHash, proofElement]))));
      } else {
        // Hash(current element of the proof + current computed hash)
        computedHash = hex(keccak256(buf(solidityPack(['bytes32', 'bytes32'], [proofElement, computedHash]))));
      }
    }
    setComputedHash(computedHash);

    // Check the computed hash against the confirmation
    const confirmation = confirmationIdx[computedHash];
    setConfirmation(confirmation || null);

    // Check if confirmed on the network
    if(confirmation) {
      const contractConfirmed = confirmationFinalizedIdx[computedHash];
      setConfirmationFinalized(contractConfirmed || null);

      // Check if the proof was used and the rewards were harvested
      if(contractConfirmed) {
        const rewardsHarvested = rewardsHarvestedIdx[hex(leafHash)];
        setRewardsHarvested(rewardsHarvested || null);
      }
    }
  }, [rewardToken, event, chainId, confirmationIdx, confirmationFinalizedIdx, rewardsHarvestedIdx])
  
  // Calculate root hash for proof
  return (
    <div>
      <span className={`px-2 py-1 text-lg ${confirmationUi != null ? 'bg-green-200' : 'bg-red-200'}`}>🌞</span>
      <span className={`ml-1 px-2 py-1 text-lg ${confirmationFinalizedUi != null ? 'bg-green-200' : 'bg-red-200'}`}>⛓️</span>
      <span className={`ml-1 px-2 py-1 text-lg ${rewardsHarvestedUi != null ? 'bg-green-200' : 'bg-red-200'}`}>🚜</span>
      <span className="block">root: {computedHashUi}</span>
      <span className="block">leaf: {leafHashUi}</span>
    </div>
  )
}