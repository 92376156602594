import {useEffect, useMemo, useState} from "react";

import { useWalletContext } from "../providers/walletProvider";
import { useDataStore } from "../../../stores/dataStore";
import { useSession } from "../../../stores/sessionStore";
import {getConfirmationFinalizedEvents, finalize, confirmationV1Iface} from "../../../contracts/confirmationsV1";
import {ConfirmationEvent} from "../../../oracles/confirmationsV1";
import {Modal} from "../../modal";

const CONFIRMATION_V1_HEADERS = [
    "#",
    'blockHash',
    'bundleHash',
    'closerHash',
    'hash',
    'number',
    'parentHash',
    'blockCount',
    'stateRoot',
    'timestamp',
    'status',
    'finalize',
];

function ConfirmationV1() {
    const {
        userAddress,
        connect,
    } = useWalletContext();

    const [confirmations] = useDataStore.confirmations();
    const [confirmationV1Addr] = useSession.confirmations();
    const [confirmationsFinalized, setConfirmationsFinalized] = useDataStore.confirmationsFinalized();
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [confirmationFunctionData, setConfirmationFunctionData] = useState("");


    const onClickConfirm = async ({ event, blockHash }: ConfirmationEvent) => {
        if (!userAddress) {
            await connect();
        }

        const { hash, number, stateRoot, parentHash, timestamp, bundleHash, closerHash, blockCount, confirmChainBlockNr } = event.args;
        await finalize({
            hash,
            number: number.toString(),
            stateRoot ,
            parentHash,
            timestamp: timestamp.toString(),
            bundleHash,
            closerHash,
            blockCount,
            blockHash,
            confirmChainBlockNr
        }, confirmationV1Addr);
        const periodConfirmedEvents = await getConfirmationFinalizedEvents();
        setConfirmationsFinalized(periodConfirmedEvents);
    };

    const onClickFinalizeViaMultiSig = async (confirmation: ConfirmationEvent) => {

        const functionData = confirmationV1Iface.encodeFunctionData('finalize', [
            confirmation.hash,
            confirmation.number,
            confirmation.stateRoot,
            confirmation.parentHash,
            confirmation.timestamp,
            confirmation.bundleHash,
            confirmation.closerHash,
            confirmation.blockCount,
            confirmation.blockHash,
            confirmation.confirmChainBlockNr,
        ])
        setConfirmationFunctionData(functionData);
        setConfirmDialogOpen(true);
    }

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    }


    const periodConfirmedEventsMap = useMemo(() => {
        if (confirmationsFinalized) {
            return new Map(
                confirmationsFinalized.map(({event}) => [event.args.blockHash, event])
            );
        }

        return new Map();

    }, [confirmationsFinalized])

    return (
        <>
            <div className="flex-column">
                <p>
                    <span className="error"> <i>Important:</i></span>
                    {" "}use developer account <strong>0xE22CCe03866D4B636bBb48A51DaD08872e64C6f6</strong> on testnet.
                </p>
            </div>
                <div className="flex-column">
                    {userAddress && <p>Account: <strong>{userAddress}</strong></p>}
                    <div className="flex-column">
                        {confirmations?.length ? (
                            <div>
                                <table className="table-auto border-collapse border border-slate-500">
                                    <thead>
                                    <tr>
                                        {CONFIRMATION_V1_HEADERS.map((h) => (
                                            <th
                                                key={`confirmationV1-header-${h}`}
                                                className="border border-slate-200 italic text-xs"
                                            >
                                                {h}
                                            </th>
                                        ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {confirmations.map((confirmation, i) => (
                                        <tr key={confirmation.blockHash}>
                                            <td className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs">{i}</td>
                                            <td className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs">
                                                <p>{confirmation.blockHash}</p>
                                            </td>
                                            <td className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs">
                                                <p>{confirmation.bundleHash}</p>
                                            </td>
                                            <td className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs">
                                                <p>{confirmation.closerHash}</p>
                                            </td>
                                            <td className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs">
                                                <p>{confirmation.hash}</p>
                                            </td>
                                            <td className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs">{confirmation.number}</td>
                                            <td className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs">
                                                <p>{confirmation.parentHash}</p>
                                            </td>
                                            <td className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs">{confirmation.blockCount}</td>
                                            <td className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs">
                                                <p>{confirmation.stateRoot}</p>
                                            </td>
                                            <td className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs">{confirmation.timestamp}</td>
                                            <td className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs">
                                                {periodConfirmedEventsMap.get(confirmation.blockHash) ? (
                                                    <p>Done &#9989;</p>
                                                ) : (
                                                    <button
                                                        className="bg-stone-300 border-2 border-black"
                                                        onClick={() => onClickConfirm(confirmation)}
                                                    >
                                                        Confirm
                                                    </button>
                                                )}
                                            </td>
                                            <td className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs">
                                                {periodConfirmedEventsMap.get(confirmation.blockHash) ? (
                                                    <p>Done &#9989;</p>
                                                ) : (
                                                    <button
                                                        className="bg-stone-300 border-2 border-black"
                                                        onClick={() => onClickFinalizeViaMultiSig(confirmation)}
                                                    >
                                                        Finalize via multisig
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>

                                <Modal
                                    isOpen={confirmDialogOpen}
                                    handleClose={handleConfirmDialogClose}
                                    title="Finalize Confirmation via MultiSig"
                                >
                                    <div>
                                        <p id="alert-dialog-description">
                                        ConfirmationV1: <span style={{ fontWeight: "bold" }}>{confirmationV1Addr}</span>
                                        </p>
                                        <br />
                                        <p>
                                            Hex Data:
                                        </p>
                                        <br />
                                        <p className='break-all'>
                                            {confirmationFunctionData}
                                        </p>
                                    </div>
                                </Modal>
                            </div>
                        ) : null}
                        (Open console to see more details)
                    </div>
                </div>
        </>
    );
}

export default ConfirmationV1;
