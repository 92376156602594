import dayjs from 'dayjs';

import { fmtTime } from '../../../lib/time';
import { buf, hex } from '../../../../../oracle/src/lib/buffer';
import { fromChainAddress } from '../../../../../oracle/src/lib/chainAddress';
import { FarmExistsEvent } from '../../../contracts/referralFarmsV1';
import { ProofOfRecommendationOrigin } from '../../../oracles/proofOfRecommendations';
import { makeDynamicComponent } from '../../dynamicComponent';

// 1 week = 604800 seconds
const FOUR_WEEK_SECONDS = 2419200;

export const generateOriginsPerReferredToken = (referrer: string, allOrigins?: ProofOfRecommendationOrigin[], farmExistsIdx?: Record<string, FarmExistsEvent>) => {
  if (!allOrigins || !farmExistsIdx) return;

  const mapRes: Map<string, any> = new Map();

  const referrerOrigins = allOrigins?.filter((origin) => origin.signer.toString().toLowerCase() === referrer.toLowerCase());

  Object.entries(farmExistsIdx).forEach(([, farm]) => {
    const [, token] = fromChainAddress(buf(farm.referredTokenDefn));
    const tokenAddr = hex(token);
    const origins = referrerOrigins.filter((origin) => origin.token.toString().toLowerCase() === tokenAddr.toLowerCase()).sort((a, b) => b.time - a.time);

    mapRes.set(tokenAddr, {
      origins: origins.map((origin) => {
        const now = fmtTime(Date.now());
        const expiredDate = dayjs(origin.fmtTime).add(FOUR_WEEK_SECONDS, 'seconds');
        const expired = expiredDate.isBefore(now);

        return {
          signer: origin.signer,
          token: origin.token,
          time: origin.time,
          fmtTime: origin.fmtTime,
          expiredDate: fmtTime(expiredDate),
          expired,
          expiredIn: makeDynamicComponent('OriginExpired', {
            expired,
            expiredDate
          }),
        };
      }),
    });
  });

  const arr: { token: string, origins: ProofOfRecommendationOrigin[] }[] = [];
  for (const [token, { origins }] of mapRes.entries()) {
    arr.push({
      token,
      origins,
    });
  }
  return arr;
};
