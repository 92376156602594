import React from 'react';
import arch from '../static/arch.png'

export default function ArchitectureModal({ hide }) {
  return (
    <div tabIndex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal h-full" onClick={_ => hide()}>
        <div className="relative p-2 w-full">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="p-2 text-center">
                    <img src={arch} />
                </div>
            </div>
        </div>
    </div>
  )
}