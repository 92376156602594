// Simple table component which supports bigints and allows some exploration
import Big from 'big.js';
import React, { memo, useEffect, useState } from "react";
import { useUiContext } from "../stores/uiContextStore";
import { Cropped } from './cropped';
import DynamicComponent from './dynamicComponent';
import { error } from './errorStack';
import SelectableColumn from './selectableColumn';

import { stringer } from "./utils/stringer"

function TableHeadRow({ cols, fields }) {
  return (
    <tr>
      {cols.filter(c => fields[c]).map(col => {
        return (
          <th className="border border-slate-200 italic text-xs" key={`th-${col}`}>{col}</th>
        )
      })}
    </tr>
  )
}

function TableRow({ rowi, row, cols, fields, aggregation }) {
  const [numberPrecision] = useUiContext.numberPrecision();
  const [units] = useUiContext.units();
  
  return (
    <tr>
      {cols
        .filter(c => fields[c])
        .map((c, i) => {
          let val;
          // Render a useful value
          if(row[c] != null) {
            let raw = row[c];
            if(raw && raw._isDynComp) {
              val = <DynamicComponent func={raw.func} args={raw.args} />;
            } else if(React.isValidElement(raw)) {
              val = raw;
            } else if(typeof raw === 'object') {
              val = JSON.stringify(raw)
            } else if(typeof raw === 'bigint') {
              // Show big numbers in a way they make some sense
              val = Big(raw.toString()).div(Big(10).pow(units)).toFixed(numberPrecision)
            } else if (raw.toString().length > 24) {
              val = <Cropped>{raw.toString()}</Cropped>;
            } else {
              val = raw.toString()
            }
          } else {
            // Fix render bug due to field selection
            val = '';
          }

          return <td 
            className="border border-slate-200 max-w-[360] text-ellipsis overflow-hidden p-1 text-xs" 
            key={`tr-${rowi}-${i}`}>
              {val}
          </td>
        })}
    </tr>
  )
}

function toClipboard(objects: any) {
  try {
    const serialized = JSON.stringify(objects);
    navigator.clipboard.writeText(serialized);
    console.log('copied to clipboard!');
  } catch(err) {
    console.error(err);
    error('could not serialize dataset, see console.');
    throw err;
  }
}

function colIsNumeric(rows, col) {
  for(let i = 0; i < rows.length && i < 200; i++) {
    const v = rows[i][col];
    if(v == null) continue;
    try {
      const works = Big(v);
      if(works) {
        return true;    
      }
      return false;
    } catch (err) {
      continue;
    }
  }
  return false;
}

const noDefaultKeys = {
  farmHash: true,
  event: true,
  log: true,
  proofValid: true,
  sig: true,
  timePromises: true,
  proof: true
}

const ObjectsExplorer = memo<any>(({ objects, textFilterVisible = true }) => {
  const [rows, setRows] = useState(null);
  const [cols, setCols] = useState(null);
  const [fields, setFields] = useState({});
  const [textFilter, setTextFilter] = useState('');

  // When new data
  useEffect(() => {
    if(!objects || objects.length == 0) return;

    setCols(Object.keys(objects[0]));
    setRows(objects);

  }, [objects]);

  useEffect(() => {
    // Parse and re-compute the data
    if(textFilter != null && textFilter.length > 1) {
      const fltr = textFilter.toLowerCase();
      objects = objects?.filter(o => stringer(o).indexOf(fltr) >= 0);
      setRows(prev => prev.filter(o => stringer(o).indexOf(fltr) >= 0))
    } else {
      setRows(objects);
    }
  }, [textFilter, objects])

  // Select at least one field
  useEffect(() => {
    if(!rows || rows.length == 0) return;

    setFields(prevFields=> {
      return Object.keys(rows[0])
      .filter(k => !noDefaultKeys[k])
      .reduce((acc, k) => {
          acc[k] = (acc[k] == undefined ? true : acc[k]);
          return acc;
        },
        {...prevFields}
      )
    });
  }, [rows]);

  return objects && cols && (
    <div>
      <div className="flex flex-wrap">
        {cols.map(col => <SelectableColumn
          key={`sel-${col}`} 
          name={col} 
          value={fields[col] || false} 
          onToggle={status => setFields(p => ({...p, [col]: status}))} />)}
      </div>

      {textFilterVisible &&
        <div className="flex border-b border-gray-200 pb-2 mb-2">
          <div className="flex flex-wrap ml-1">
            <input type="text" className="border text-xs" style={{ width: '400px' }} value={textFilter} onChange={e => setTextFilter(e.target.value)} />
          </div>
        </div>
      }

      {(rows == null || rows.length == 0) && 'No values (check filters)'}
      {rows && rows.length > 0 && (
        <table className="table-auto border-collapse border border-slate-500">
          <thead>
            <TableHeadRow cols={cols} fields={fields} />
          </thead>
          <tbody className="font-mono text-[0.9em]">
            {rows.map((row, i) => <TableRow key={`row-${i}`} rowi={i} row={row} cols={cols} fields={fields} /* aggregation={aggregation} */ />)}
          </tbody>
        </table>
      )}

      <button className='text-xs' onClick={() => toClipboard(objects)}>Copy json</button>
    </div>
  );
});

export default ObjectsExplorer;