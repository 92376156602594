import { Interface } from "@ethersproject/abi";
import { useState, useCallback } from "react";
import useSWR from "swr/immutable";
import { hex } from "../../../oracle/src/lib/buffer";
import { Hex32 } from "../../../oracle/src/lib/types";
import { toReactorAddress } from "../../../oracle/src/rpc/lib/address";
import { FarmExistsEvent } from "../contracts/referralFarmsV1";
import { rpcCall, RpcRoute } from "./rpcHelpers";
import { error } from '../components/errorStack';

const iface = new Interface([
  'function getLastConfirmationReward(bytes32 farmHash) view returns (uint128)',
  'function getFarmTrackedRewardValue(bytes32 farmHash) view returns (uint128)',
]);

export async function getLastConfirmationReward(farmHash: Hex32) : Promise<bigint> {
  const data = iface.encodeFunctionData('getLastConfirmationReward', [farmHash]);
  
  return BigInt(await rpcCall(RpcRoute.rpc, hex(toReactorAddress('referralFarmsV1Reactor')), data));
}

export interface LastConfirmationReward {
  farmHash: Hex32;
  reward: bigint;
}

export async function getLastConfirmationRewards(events: FarmExistsEvent[]) : Promise<LastConfirmationReward[]> {
  const uniques = {};
  const res: LastConfirmationReward[] = [];
  for(let { farmHash } of events) {
    if(uniques[farmHash]) continue;
    try {
      const reward = await getLastConfirmationReward(farmHash);
      res.push({
        farmHash,
        reward,
      })
      uniques[farmHash] = true;
    } catch (err) {
      error(`failed fetching farm ${farmHash}`, err);
    }
  }
  return res;
} 

export async function getFarmTrackedRewardValue(farmHash: Hex32): Promise<bigint> {
  const data = iface.encodeFunctionData('getFarmTrackedRewardValue', [farmHash])
  return BigInt(await rpcCall(RpcRoute.rpc, hex(toReactorAddress('referralFarmsV1Reactor')), data))
}

export interface FarmTrackedRewardValue {
  farmHash: Hex32
  reward: bigint
}

export async function getFarmsTrackedRewardValue(farmHashes: Hex32[]): Promise<FarmTrackedRewardValue[]> {
  const uniques = {}
  const res: FarmTrackedRewardValue[] = []
  for (const farmHash of farmHashes) {
      if (uniques[farmHash as string]) continue
      try {
          const reward = await getFarmTrackedRewardValue(farmHash)
          res.push({
              farmHash,
              reward,
          })
          uniques[farmHash as string] = true
      } catch (err) {
          error(`failed fetching farm ${farmHash}`, err)
      }
  }

  return res
}

export function useFarmsTrackedRewardValue(farmHashes: Hex32[]) {
  const [shouldFetch, setShouldFetch] = useState(false)

  const fetchFarmsTrackedRewardValue = useCallback(() => {
      return farmHashes?.length && getFarmsTrackedRewardValue(farmHashes)
  }, [farmHashes])

  const { data, error } = useSWR(
      () => (shouldFetch ? [`farms.farmTrackedRewardValue-${farmHashes.join('+')}`] : null),
      fetchFarmsTrackedRewardValue,
  )

  return {
      ensure: () => setShouldFetch(true),
      data,
      error,
  }
}