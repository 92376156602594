import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDerivedBlockHead } from "../oracles/chain";

export default function BlockLag({ className }) {
  const [blockLag, setBlockLag] = useState<number>(0);
  const { ensure: ensureDerivedBlockHead, data: header } = useDerivedBlockHead();

  useEffect(() => {
    ensureDerivedBlockHead(true);
  }, []);

  useEffect(() => {
    if(!header) return;
    const closerTs = Number(header.closer.timestamp);
    const lag = Math.round((dayjs().unix() - closerTs) / 15); // Est eth pm time
    setBlockLag(lag);
  }, [header]);

  return blockLag >= 0 && (
    <div className={`${className} text-xs ${ blockLag > 20 ? 'bg-red-400' : '' }`}>lag: ~{blockLag}blocks</div>
  );
}