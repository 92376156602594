import { keyBy, uniq } from "lodash";
import { useMemo, useState} from "react";
import useSWR from "swr/immutable";
import useAsyncEffect from "use-async-effect";
import { makeDynamicComponent } from "../components/dynamicComponent";
import { getConfirmationFinalizedEvents } from "../contracts/confirmationsV1";
import { getSettingConfiguredEvents } from "../contracts/oracleAuthorityV1";
import { getFarmDepositIncreasedEvents, getFarmExistsEvents, getFarmMetastateEvents, getRewardsHarvestedEvents } from "../contracts/referralFarmsV1";
import { getConfirmationEvents } from "../oracles/confirmationsV1";
import { getEntitlementEvents } from "../oracles/entitlementsV1";
import { FarmPositionJoinedEvent, getPositionSizes, useFarmPositionDecreasedEvents, useFarmPositionIncreasedEvents, useFarmPositionJoinedEvents } from "../oracles/farmPositionsV1";
import { getFarmTokenSizes } from "../oracles/farmTokenSizeV1";
import { getLastConfirmationRewards } from "../oracles/referralFarmsV1";
import { useDataStore } from "../stores/dataStore";
import { useSession } from "../stores/sessionStore";
import { useUiContext } from "../stores/uiContextStore";

export function useFilteredData() {
  // Session
  const [farms] = useSession.farms();

  const [numberPrecision] = useUiContext.numberPrecision();
  const [filterHashes] = useUiContext.filterHashes();
  const [units] = useUiContext.units();

  // Full datasets
  const [isLoaded, setIsLoaded] = useDataStore.isLoaded();
  const [settingsConfigured, setSettingConfigured] = useDataStore.settingsConfigured();
  const [farmExists, setFarmExists] = useDataStore.farmExists();
  const [farmExistsIdx, setFarmExistsIdx] = useDataStore.farmExistsIdx();
  const [farmDepositIncreased, setFarmDepositIncreased] = useDataStore.farmDepositIncreased();
  const [farmMetastate, setFarmMetastate] = useDataStore.farmMetastate();
  const [rewardsHarvested, setRewardsHarvested] = useDataStore.rewardsHarvested();
  const [rewardsHarvestedIdx, setRewardsHarvestedIdx] = useDataStore.rewardsHarvestedIdx();
  const [confirmationsFinalized, setConfirmationsFinalized] = useDataStore.confirmationsFinalized();
  const [confirmationFinalizedIdx, setConfirmationFinalizedIdx] = useDataStore.confirmationFinalizedIdx();
  const [confirmations, setConfirmations] = useDataStore.confirmations();
  const [confirmationIdx, setConfirmationIdx] = useDataStore.confirmationIdx();
  const [entitlements, setEntitlements] = useDataStore.entitlements();
  const [farmTokenSizes, setFarmTokenSizes] = useDataStore.farmTokenSizes();
  const [lastConfirmationRewards, setLastConfirmationRewards] = useDataStore.lastConfirmationRewards();

  // Data Loader (we load the "datastore" with all events from all the core contracts)
  useAsyncEffect(async () => {
    if(!farms) return;
    // Load all contract events
    setSettingConfigured(await getSettingConfiguredEvents());
    const confirmationFinalizedEvents = await getConfirmationFinalizedEvents();
    setConfirmationsFinalized(confirmationFinalizedEvents);
    setConfirmationFinalizedIdx(keyBy(confirmationFinalizedEvents, d => d.confirmationHash));
    const farmExistsEvents = await getFarmExistsEvents();
    setFarmExists(farmExistsEvents);
    setFarmExistsIdx(keyBy(farmExistsEvents, d => d.farmHash));
    const farmDepositIncreasedEvents = await getFarmDepositIncreasedEvents();
    setFarmDepositIncreased(farmDepositIncreasedEvents);
    setFarmMetastate(await getFarmMetastateEvents());
    const rewardsHarvestedEvents = await getRewardsHarvestedEvents();
    setRewardsHarvested(rewardsHarvestedEvents);
    setRewardsHarvestedIdx(keyBy(rewardsHarvestedEvents, d => d.leafHash));

    // Load all oracle events
    const confirmationEvents = await getConfirmationEvents();
    setConfirmations(confirmationEvents);
    setConfirmationIdx(keyBy(confirmationEvents, d => d.hash));

    const entitlementEvents = await getEntitlementEvents(filterHashes);
    // Extend with a proof component
    const sumsByConfirmation = entitlementEvents.reduce((acc, v, i) => {
      const k = '.'+v.confirmation;
      if(!acc[k]) acc[k] = 0n;
      acc[k] += v.rewardValue;
      return acc;
    }, {});
    
    setEntitlements(entitlementEvents.map(event => {
      return {
        ...event,
        proofValid: makeDynamicComponent('ProofValidate', { event }),
        sumByConfirmation: sumsByConfirmation['.' + event.confirmation]
      }
    }));

    // Build list of known positions
    setFarmTokenSizes(await getFarmTokenSizes(farmExistsEvents));
    setLastConfirmationRewards(await getLastConfirmationRewards(farmExistsEvents));

    setIsLoaded(true);
  }, [farms, filterHashes]);

  // Expose all values
  return {
    isLoaded,
    settingsConfigured,
    farmExists,
    farmExistsIdx,
    farmDepositIncreased,
    farmMetastate,
    rewardsHarvested,
    rewardsHarvestedIdx,
    confirmationFinalized: confirmationsFinalized,
    confirmationFinalizedIdx,
    confirmation: confirmations,
    confirmationIdx,
    entitlements,
    farmTokenSizes,
    lastConfirmationRewards,
  }
}

// All farm hashes found in any of the responses
export function useFarmHashes() {
  const [farmExists] = useDataStore.farmExists();
  const [farmDepositIncreased] = useDataStore.farmDepositIncreased();
  const [entitlements] = useDataStore.entitlements();
  const { ensure: ensureFarmPositionJoined, data: farmPositionJoined, error: errorFarmPositionJoined } = useFarmPositionJoinedEvents();
  const { ensure: ensureFarmPositionIncreased, data: farmPositionIncreased, error: errorFarmPositionIncreased } = useFarmPositionIncreasedEvents();
  const { ensure: ensureFarmPositionDecreased, data: farmPositionDecreased, error: errorFarmPositionDecreased } = useFarmPositionDecreasedEvents();

  const data = useMemo(() => {
    if(farmExists == null) return;

    return uniq([
      ...farmExists.map(e => e.farmHash),
      ...farmDepositIncreased.map(e => e.farmHash),
      ...farmPositionJoined.map(e => e.farmHash),
      ...farmPositionIncreased.map(e => e.farmHash),
      ...farmPositionDecreased.map(e => e.farmHash),
      ...entitlements.map(e => e.farmHash),
    ])
    .sort();
  }, [farmExists, farmDepositIncreased, farmPositionJoined, farmPositionIncreased, farmPositionDecreased, entitlements]);

  return {
    ensure: () => {
      ensureFarmPositionJoined();
      ensureFarmPositionIncreased();
      ensureFarmPositionDecreased();
    },
    data,
    error: errorFarmPositionDecreased || errorFarmPositionIncreased || errorFarmPositionJoined,
  }
}

export function useFilteredFarmHashes() {
  // Filters
  const [filterHashes] = useUiContext.filterHashes();
  const { ensure, data: farmHashes, error } = useFarmHashes();

  let data = farmHashes;
  if(filterHashes && filterHashes.length > 0) {
    const idx = keyBy(filterHashes);
    data = farmHashes.filter(h => idx[h] >= 0);  
  } 

  return {
    ensure,
    data,
    error,
  }
}

async function tokenPositionsFetcher(farmPositionJoined: FarmPositionJoinedEvent[]) {
  return await getPositionSizes(farmPositionJoined);
}

export function useTokenPositions() {
  const [shouldFetch, setShouldFetch] = useState(false);
  const { ensure: getFarmPositionJoined, data: farmPositionJoined, error: errorFarmPositionJoined } = useFarmPositionJoinedEvents();

  const { data, error } = useSWR(() => farmPositionJoined != null && shouldFetch ? [farmPositionJoined, 'tokenPositions'] : null, tokenPositionsFetcher);

  return {
    ensure: () => { 
      getFarmPositionJoined();
      setShouldFetch(true);
    },
    data, 
    error: errorFarmPositionJoined || error || null,
  }
}
