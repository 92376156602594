import { buf, hex } from '../../../oracle/src/lib/buffer';
import { fromChainAddress } from '../../../oracle/src/lib/chainAddress';
import { useFarmTokenInfo } from "../hooks/farms";

export function FarmTokenActivity({ farmHash, className }) {
  const { referredTokenDefn } = useFarmTokenInfo(farmHash);
  const [chainId, token] = fromChainAddress(buf(referredTokenDefn));

  return (
    <div className={`${className}`}>
      GOAL: get useful insights in what token activity happened for a token (related to what we do)
      
      TODO: efficient compact visualization of erc20 trades with the referred token of passed farm

      WIRING: pull from blockchain via ethers

      {chainId.toString() } {hex(token)}
    </div>
  )
}