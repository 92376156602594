import { ethers } from "ethers";
import { trim } from "lodash";
import React, { useState } from "react";
import { useCallback } from "react";
import { hex } from "../../../oracle/src/lib/buffer";
import { toReactorAddress } from "../../../oracle/src/rpc/lib/address";
import { getProvider } from "../helpers";
import { rpcCall, RpcRoute } from "../oracles/rpcHelpers";

export default function HumanAbiEvalOracle() {
  const reactorLabel = 'reactor, eg: farmPositionsV1Reactor';
  const funcLabel = `function, eg: function getNegativeBalance(bytes24 referredToken, address holder) view returns (uint128)`;
  const argsLabel = `args in json, eg: []`;

  const [reactor, setReactor] = useState('farmPositionsV1Reactor');
  const [func, setFunc] = useState('function getNegativeBalance(bytes24 referredToken, address holder) view returns (uint128)');
  const [args, setArgs] = useState('["0x00000004c778417e063141139fce010982780140aa0cd5ab","0x26bc596d39c5d45c855901b6995e0842e37ae57a"]');
  const [output, setOutput] = useState('');

  const execute = useCallback(() => {
    setOutput('');
    (async () => {
      try {
        const reactorId = hex(toReactorAddress(reactor));
        const iface = new ethers.utils.Interface([ func ]);
        const parsed = JSON.parse(args);
        const data = iface.encodeFunctionData(iface.fragments[0], parsed);
        console.log({ reactorId, data });
        let res = await rpcCall(RpcRoute.rpc, reactorId, data);
        console.log({ res });
        if(res === '0x0') {
          res = '0x00';
        }
        const evmEncoded = iface.encodeFunctionResult(iface.fragments[0], [res]);
        const parsedResult = iface.decodeFunctionResult(iface.fragments[0], evmEncoded);
        console.log({ parsedResult });
        setOutput(JSON.stringify(parsedResult));
      } catch(err) {
        console.error(err);
        setOutput('error see console.' + err.toString());
      }
    })();
  }, [reactor, func, args]);

  return (
    <div>
      open console for output or improve tool
      <div>
        <label>{reactorLabel}</label><br />
        <input className="border w-96 h-8 text-xs pb-0 mb-0" type="text" value={reactor} onChange={e => setReactor(e.target.value)} placeholder={reactorLabel} />
      </div>
      <div>
        <label>{funcLabel}</label><br />
        <textarea className="border w-96 h-8 text-xs pb-0 mb-0" value={func} onChange={e => setFunc(e.target.value)} placeholder={funcLabel} />
      </div>
      <div>
        <label>{argsLabel}</label><br />
        <textarea className="border w-96 h-8 text-xs pb-0 mb-0" value={args} onChange={e => setArgs(e.target.value)} placeholder={argsLabel} />
      </div>
      <pre>{output}</pre>
      <button onClick={() => execute()}>run</button>
    </div>
  )
}