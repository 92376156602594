import { calcFarmPositionCount, calcFarmPositionSize, useFarmPositionJoinedEvents } from "../oracles/farmPositionsV1";
import { useTokenPositions } from "./data";

export function useFarmPositionJoined(farmHash) {
  const { ensure, data: farmPositionJoined, error } = useFarmPositionJoinedEvents();
  const data = farmPositionJoined && farmPositionJoined.filter(e => e.farmHash === farmHash) || null;
  
  return { ensure, data, error };
}

export function useFarmPositionsCount(farmHash) {
  const { ensure, data: farmPositionJoined, error } = useFarmPositionJoinedEvents();
  const data = farmPositionJoined && calcFarmPositionCount(farmPositionJoined, farmHash) || null;

  return { ensure, data, error };
}

export function useFarmPositionsSize(farmHash) {
  const { ensure, data: tokenPositions, error } = useTokenPositions();
  const data = tokenPositions && calcFarmPositionSize(tokenPositions, farmHash) || null;

  return { ensure, data, error };
}
