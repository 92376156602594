import { useSession } from '../stores/sessionStore';
import BlockLag from './blockLag';
import { Cropped } from './cropped';

export function ShowOracle({ className = ''}) {
  const [chainId] = useSession.chainId();
  const [authority] = useSession.authority();
  const [farms] = useSession.farms();
  const [confirmations] = useSession.confirmations();

  return (
    <div className={`${className} border-b-2 border-black`}>
      <div className="flex">
        <BlockLag className={""} />
        <div className="ml-1 text-xs pt-2"><b>Chain</b>: {chainId}</div>
        <div className="ml-1 text-xs pt-2"><Cropped><b>Authority</b>: {authority}</Cropped></div>
        <div className="ml-1 text-xs pt-2"><Cropped><b>Farms</b>: {farms}</Cropped></div>
        <div className="ml-1 text-xs pt-2 flex-grow"><Cropped><b>Confirmations</b>: {confirmations}</Cropped></div>

        <div className="border-b mb-1 border-t border-gray">
          <span className="border-1 px-2 m-2 text-xs ml-0 rounded-none bg-gray-100">source contracts</span>
          <span className="border-1 px-2 m-2 text-xs rounded-none bg-yellow-100">contract-events</span>
          <span className="border-1 px-2 m-2 text-xs rounded-none bg-sky-100">oracle-recommendations</span>
          <span className="border-1 px-2 m-2 text-xs rounded-none bg-green-100">oracle-reactor-events</span>
          <span className="border-1 px-2 m-2 text-xs rounded-none bg-purple-100">oracle-reactor-rpc</span>
        </div>
      </div>
    </div>
  );
}