import { useEffect } from "react";
import { useFarmPositionsCount, useFarmPositionsSize } from "../hooks/positions";
import FarmPositions from "./farmPositions";
import TokenValue from "./tokenValue";

export default function OracleFarm({ farmHash, className }) {
  const { ensure: waste, data: positionsCount } = useFarmPositionsCount(farmHash);
  const { ensure: offTime, data: positionsSize } = useFarmPositionsSize(farmHash);
  useEffect(waste, []);
  useEffect(offTime, []);

  return (
    <div className={`${className}`}>
      <div className="border-b border-gray-300">
        <span className="italic">positionsCount: {positionsCount} </span>
        <span className="italic pl-2">positionsSize: <TokenValue value={positionsSize} /></span>
      </div>
      <FarmPositions farmHash={farmHash} className=""/>
    </div>
  );
}