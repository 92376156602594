import { useEffect, useMemo, useState } from "react";
import { usePathRuleSetEvents } from "../contracts/authoritySettingValidatorV1";
import { usePendingSettingCancelledEvents } from "../contracts/oracleAuthorityV1";
import { useFarmDepositDecreaseClaimedEvents, useFarmDepositDecreaseRequestedEvents } from "../contracts/referralFarmsV1";
import { useFarmHashes, useFilteredData, useTokenPositions } from "../hooks/data";
import { useLockedEntitlementEvents } from "../oracles/entitlementsV1";
import { useFarmPositionJoinedEvents, useFarmPositionSnapshotEvents } from "../oracles/farmPositionsV1";
import { useScanProofOfRecommendationOrigins, useScanProofOfRecommendations } from "../oracles/proofOfRecommendations";
import { useFarmsTrackedRewardValue } from "../oracles/referralFarmsV1";
import { useDataStore } from "../stores/dataStore";
import { useUiContext } from "../stores/uiContextStore";
import { error } from "./errorStack";
import ObjectsExplorer from "./objectsExplorer";
import {
  groupDepositsByUniqReferredAndRewardTokens,
  groupEntitlementsByUniqReferredAndRewardTokens,
  useFarmPositionDecreasedWithConfirmationEvents,
  useFarmPositionIncreasedWithConfirmationEvents,
  aggregateTokenPositions,
  aggregateEntitlements,
  aggregateLockedEntitlements,
} from "./utils/aggregation";
import { stringer } from './utils/stringer';


export default function InspectDataStore() {
  const [objects, setObjects] = useState([]);
  const [filteredObjects, setFilteredObjects] = useState([]);

  const [isLoaded, setIsLoaded] = useDataStore.isLoaded();
  const [filterHashes] = useUiContext.filterHashes();
  const [units] = useUiContext.units();
  const [numberPrecision] = useUiContext.numberPrecision();

  const {
    settingsConfigured,
    farmExists,
    farmExistsIdx,
    farmDepositIncreased,
    farmMetastate,
    rewardsHarvested,
    confirmationFinalized,
    confirmation,
    entitlements: entitlementsData,
    farmTokenSizes,
    lastConfirmationRewards,
  } = useFilteredData();

  const { ensure: ensureFarmHashes, data: farmHashes, error: errorFarmHashes } = useFarmHashes();
  const { ensure: ensureRecommendations, data: recommendations, error: errorRecommendations } = useScanProofOfRecommendations();
  const { ensure: ensureOrigins, data: origins, error: errorOrigins } = useScanProofOfRecommendationOrigins();
  const { ensure: ensurePathRuleSet, data: pathRuleSet, error: errorPathRuleSet } = usePathRuleSetEvents();
  const { ensure: ensurePendingSettingCancelled, data: pendingSettingCancelled, error: errorPendingSettingCancelled } = usePendingSettingCancelledEvents();
  const { ensure: ensureFarmDepositDecreaseRequested, data: farmDepositDecreaseRequested, error: errorFarmDepositDecreaseRequested } = useFarmDepositDecreaseRequestedEvents();
  const { ensure: ensureFarmDepositDecreaseClaimed, data: farmDepositDecreaseClaimed, error: errorFarmDepositDecreaseClaimed } = useFarmDepositDecreaseClaimedEvents();
  const { ensure: ensureFarmsTrackedRewardValue, data: farmsTrackedRewardValue, error: errorFarmsTrackedRewardValue } = useFarmsTrackedRewardValue(farmHashes);
  const { ensure: ensureFarmPositionJoined, data: farmPositionJoined, error: errorFarmPositionJoined } = useFarmPositionJoinedEvents();
  const { ensure: ensureFarmPositionIncreased, data: farmPositionIncreased, error: errorFarmPositionIncreased } = useFarmPositionIncreasedWithConfirmationEvents();
  const { ensure: ensureFarmPositionDecreased, data: farmPositionDecreased, error: errorFarmPositionDecreased } = useFarmPositionDecreasedWithConfirmationEvents();
  const { ensure: ensureFarmPositionSnapshot, data: farmPositionSnapshot, error: errorFarmPositionSnapshot } = useFarmPositionSnapshotEvents();
  const { ensure: ensureTokenPositions, data: tokenPositionsData, error: errorTokenPositions } = useTokenPositions();
  const lockedEntitlementsData = useLockedEntitlementEvents();

  useEffect(() => {
    // TODO: only on active tab
    ensureFarmHashes();
    ensureRecommendations(true);
    ensureOrigins(true);
    ensurePathRuleSet();
    ensurePendingSettingCancelled();
    ensureFarmDepositDecreaseRequested();
    ensureFarmDepositDecreaseClaimed();
    ensureFarmsTrackedRewardValue();
    ensureFarmPositionJoined();
    ensureFarmPositionIncreased();
    ensureFarmPositionDecreased();
    ensureFarmPositionSnapshot();
    ensureTokenPositions();
  }, []);

  useEffect(() => {
    if(errorFarmHashes) error(errorFarmHashes);
    if(errorRecommendations) error(errorRecommendations);
    if(errorOrigins) error(errorOrigins);
    if(errorPathRuleSet) error(errorPathRuleSet);
    if(errorPendingSettingCancelled) error(errorPendingSettingCancelled);
    if(errorFarmDepositDecreaseRequested) error(errorFarmDepositDecreaseRequested);
    if(errorFarmDepositDecreaseClaimed) error(errorFarmDepositDecreaseClaimed);
    if(errorFarmsTrackedRewardValue) error(errorFarmsTrackedRewardValue);
    if(errorFarmPositionJoined) error(errorFarmPositionJoined);
    if(errorFarmPositionIncreased) error(errorFarmPositionIncreased);
    if(errorFarmPositionDecreased) error(errorFarmPositionDecreased);
    if(errorTokenPositions) error(errorTokenPositions);
    if(errorFarmPositionSnapshot) error(errorFarmPositionSnapshot);
  }, [errorRecommendations, errorOrigins, errorPathRuleSet, errorPendingSettingCancelled, errorFarmDepositDecreaseRequested, errorFarmDepositDecreaseClaimed, errorFarmPositionJoined, errorFarmPositionIncreased, errorFarmPositionDecreased, errorTokenPositions, errorFarmHashes, errorFarmPositionDecreased, errorFarmPositionIncreased, errorFarmPositionSnapshot]);

  useEffect(() => {
    if (filterHashes && filterHashes.length > 0) {
      const hashes = filterHashes.map(d => d.toLowerCase());
      setFilteredObjects(objects?.filter(o => {
        const str = stringer(o);
        for (let h of hashes) {
          if (str.indexOf(h) >= 0) return true;
        }
        return false;
      }))
    } else {
      setFilteredObjects(objects);
    }
  }, [objects, filterHashes])

  const depositsUniqReferredAndRewardTokens = useMemo(
    () => groupDepositsByUniqReferredAndRewardTokens(farmExistsIdx, farmDepositIncreased, farmDepositDecreaseClaimed, farmsTrackedRewardValue),
    [farmExistsIdx, farmDepositIncreased, farmDepositDecreaseClaimed, farmsTrackedRewardValue],
  )
  const entitlementsUniqReferredAndRewardTokens = useMemo(
      () => groupEntitlementsByUniqReferredAndRewardTokens(entitlementsData, farmExistsIdx),
      [entitlementsData],
  )
  const tokenPositions = useMemo(
    () =>  aggregateTokenPositions(tokenPositionsData, units, numberPrecision),
      [tokenPositionsData, units, numberPrecision],
  )
  const entitlements = useMemo(
    () => aggregateEntitlements(entitlementsData, units, numberPrecision),
    [entitlementsData, units, numberPrecision],
  );
  const lockedEntitlements = useMemo(
    () => aggregateLockedEntitlements(lockedEntitlementsData, units, numberPrecision),
    [lockedEntitlementsData, units, numberPrecision],
  );

  return isLoaded && (

   <div className="mt-4 border-t-2 border-black">
     <div className="flex flex-wrap border-t border-gray-200">
        <button className="border-1 px-2 m-1 border bg-yellow-100 border-green-900 text-xs focus:outline-double" onClick={_ => setObjects(settingsConfigured)}>settingsConfigured</button>
        <button className="border-1 px-2 m-1 border bg-yellow-100 border-green-900 text-xs focus:outline-double" onClick={_ => setObjects(pendingSettingCancelled)}>pendingSettingCancelled</button>
        <button className="border-1 px-2 m-1 border bg-yellow-100 border-green-900 text-xs focus:outline-double" onClick={_ => setObjects(pathRuleSet)}>pathRuleSet</button>
        <button className="border-1 px-2 m-1 border bg-yellow-100 border-green-900 text-xs focus:outline-double" onClick={_ => setObjects(farmExists)}>farmExists</button>
        <button className="border-1 px-2 m-1 border bg-yellow-100 border-green-900 text-xs focus:outline-double" onClick={_ => setObjects(farmDepositIncreased)}>farmDepositIncreased</button>
        <button className="border-1 px-2 m-1 border bg-yellow-100 border-green-900 text-xs focus:outline-double" onClick={_ => setObjects(farmMetastate)}>farmMetastate</button>
        <button className="border-1 px-2 m-1 border bg-yellow-100 border-green-900 text-xs focus:outline-double" onClick={_ => setObjects(rewardsHarvested)}>rewardsHarvested</button>
        <button className="border-1 px-2 m-1 border bg-yellow-100 border-green-900 text-xs focus:outline-double" onClick={_ => setObjects(farmDepositDecreaseRequested)}>farmDepositDecreaseRequested</button>
        <button className="border-1 px-2 m-1 border bg-yellow-100 border-green-900 text-xs focus:outline-double" onClick={_ => setObjects(farmDepositDecreaseClaimed)}>farmDepositDecreaseClaimed</button>
        <button className="border-1 px-2 m-1 border bg-yellow-100 border-green-900 text-xs focus:outline-double" onClick={_ => setObjects(confirmationFinalized)}>confirmationFinalized</button>
        <button className="border-1 px-2 m-1 border bg-sky-100 border-blue-900 text-xs focus:outline-double" onClick={_ => setObjects(origins)}>origins</button>
        <button className="border-1 px-2 m-1 border bg-sky-100 border-blue-900 text-xs focus:outline-double" onClick={_ => setObjects(recommendations)}>recommendations</button>
        <button className="border-1 px-2 m-1 border bg-green-100 border-blue-900 text-xs focus:outline-double" onClick={_ => setObjects(farmPositionJoined)}>farmPositionJoined</button>
        <button className="border-1 px-2 m-1 border bg-green-100 border-blue-900 text-xs focus:outline-double" onClick={_ => setObjects(farmPositionIncreased)}>farmPositionIncreased</button>
        <button className="border-1 px-2 m-1 border bg-green-100 border-blue-900 text-xs focus:outline-double" onClick={_ => setObjects(farmPositionDecreased)}>farmPositionDecreased</button>
        <button className="border-1 px-2 m-1 border bg-green-100 border-blue-900 text-xs focus:outline-double" onClick={_ => setObjects(farmPositionSnapshot)}>farmPositionSnapshot</button>
        <button className="border-1 px-2 m-1 border bg-green-100 border-blue-900 text-xs focus:outline-double" onClick={_ => setObjects(confirmation)}>confirmation</button>
        <button className="border-1 px-2 m-1 border bg-green-100 border-blue-900 text-xs focus:outline-double" onClick={_ => setObjects(entitlements)}>entitlements</button>
        <button className="border-1 px-2 m-1 border bg-green-100 border-blue-900 text-xs focus:outline-double" onClick={_ => setObjects(lockedEntitlements)}>lockedEntitlements</button>
        <button className="border-1 px-2 m-1 border bg-purple-100 border-blue-900 text-xs focus:outline-double" onClick={_ => setObjects(tokenPositions)}>tokenPositions</button>
        <button className="border-1 px-2 m-1 border bg-purple-100 border-blue-900 text-xs focus:outline-double" onClick={_ => setObjects(farmTokenSizes)}>farmTokenSizes</button>
        <button className="border-1 px-2 m-1 border bg-purple-100 border-blue-900 text-xs focus:outline-double" onClick={_ => setObjects(lastConfirmationRewards)}>lastConfirmationRewards</button>
        <button className="border-1 px-2 m-1 border bg-yellow-100 border-green-900 text-xs focus:outline-double" onClick={_ => setObjects(depositsUniqReferredAndRewardTokens)}>depositsUniqReferredAndRewardTokens</button>
        <button className="border-1 px-2 m-1 border bg-yellow-100 border-green-900 text-xs focus:outline-double" onClick={_ => setObjects(entitlementsUniqReferredAndRewardTokens)}>entitlementsUniqReferredAndRewardTokens</button>
      </div>
      {filteredObjects != null && <ObjectsExplorer objects={filteredObjects} /> || 'select properties above'}
    </div>
  );
}