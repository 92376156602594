import { useEffect, useState } from "react";
import { Hex32 } from "../../../../oracle/src/lib/types";
import { useFarmHashes } from "../../hooks/data";
import { useDataStore } from "../../stores/dataStore";
import { useSession } from "../../stores/sessionStore";
import SelectableColumn from "../selectableColumn";
import FarmPositionsView from "./farmPositionsView";

export default function TransfersView() {
  const { ensure: ensureFarmHashes, data: globalFarmHashes, error: errorFarmHashes } = useFarmHashes();

  interface FarmHashSelect {
    hash: Hex32,
    selected: boolean,
  }
  const [farmHashes, setFarmHashes] = useState<FarmHashSelect[]>([]);
  const [isLoaded, setIsLoaded] = useDataStore.isLoaded();

  const [chainId] = useSession.chainId()

  useEffect(() => {
    if (globalFarmHashes?.length == farmHashes.length) return; // No change, skip

    // console.log('globalFarmHashes', globalFarmHashes)
    const cloneFarmHashes: any = globalFarmHashes?.map(d => ({
      hash: d,
      selected: false,
    }));
    setFarmHashes(cloneFarmHashes);
  }, [globalFarmHashes])

  const onToggleFarmHashHandle = (fh: FarmHashSelect, selected: boolean) => {
    const newFarmHashes: FarmHashSelect[] = farmHashes.map(d => {
      return {
        ...d
      }
    })
    // Un select all
    for (const f of newFarmHashes) {
      if (f.hash == fh.hash) {
        f.selected = selected
        continue
      }
      f.selected = false
    }

    setFarmHashes(newFarmHashes)
  }

  if (!isLoaded) { return (<div>Loading transfer views...</div>) }

  return (
    <div className="transfer-view">
      <div className="farms-filter">
        <div>Farm Hashes</div>
        {farmHashes.map(fh => <SelectableColumn 
          key={`sel-fh-${fh.hash}-${fh.selected}`} // May need to check the key, work-round to make update working
          name={fh.hash} 
          value={fh.selected} 
          onToggle={status => onToggleFarmHashHandle(fh, status)} 
          />)}
      </div>

      <div className="mt-5">
        <h3>Positions</h3>

        {farmHashes.filter(f => f.selected).map(f => <FarmPositionsView key={f.hash.toString()} farmHash={f.hash} chainId={chainId}></FarmPositionsView>)}
      </div>
    </div>
  )
}

