import { ethers } from "ethers";

import { b32str, buf, hex } from "../../../oracle/src/lib/buffer";
import { fromChainAddress } from "../../../oracle/src/lib/chainAddress";
import { ZERO_HASH } from "../../../oracle/src/lib/types";
import { getSession } from "../stores/sessionStore";


const DEVELOPER_SUPPORT_V1_ABI = [`function requestChange(bytes32[] change, bytes number)`];

const changeConfirmNextBlock = b32str("confirmNextBlock");

export async function requestConfirmation() {
  try {
    const [chaddr] = getSession.developerSupportV1();
    if (!chaddr) throw new Error("There is no developerSupportV1Addr")


    const provider = await new ethers.providers.Web3Provider(window.ethereum);
    const sponsor = provider.getSigner();

    const [, addr] = fromChainAddress(buf(chaddr));
    const devSupportV1 = new ethers.Contract(
      hex(addr),
      DEVELOPER_SUPPORT_V1_ABI,
      provider
    );

    const tx = await devSupportV1
      .connect(sponsor)
      .requestChange([changeConfirmNextBlock], ZERO_HASH);
    console.log({ tx });

    return tx;
  } catch (e) {
    console.log(e.message);
    return `Error: ${e.message}`;
  }
}
