import { BigNumber, Contract, ethers, Event } from "ethers";
import { defaultAbiCoder } from "ethers/lib/utils";
import { useState } from "react";
import useSWR from "swr/immutable";
import { Hex32 } from "../../../oracle/src/lib/types";
import { AuthorityConfig, hashPath, toHumanPath } from '../../../oracle/src/state/state';
import { getProvider } from "../helpers";
import { getNetworkConfig, setSession } from "../stores/sessionStore";
import { getAuthority } from "./oracleAuthorityV1";

const iface = new ethers.utils.Interface([
  "event PathRuleSet(bytes32 indexed path0, bytes32 indexed pathIdx, bytes32[] path, (boolean isFrozen, uint64 lockedUntil) rule)",
]);

export async function getAuthoritySettingValidatorV1() : Promise<Contract> {
  const authority = await getAuthority();
  const addr = await authority.settingValidator();

  return new ethers.Contract(addr, iface, getProvider());
}

interface SettingRule {
  isFrozen: boolean;
  lockedUntil: number;
}

export interface PathRuleSetEvent {
  fmtPath: string;
  path0: Hex32;
  pathIdx: Hex32;
  path: Hex32[];
  rule: SettingRule;
  event: Event;
}

export async function pathRuleSetEventsFetcher() : Promise<PathRuleSetEvent[]> {
  const contract = await getAuthoritySettingValidatorV1();
  const events = await contract.queryFilter(contract.filters.PathRuleSet());
  return events.map(e => {
    return {
      fmtPath: toHumanPath(e.args.path),
      path0: e.args.path0,
      pathIdx: e.args.pathIdx,
      path: e.args.path,
      rule: {
        isFrozen: e.args.rule.isFrozen,
        lockedUntil: e.args.rule.lockedUntil.toBigInt(),
      },
      event: e,
    }
  });
}

export function usePathRuleSetEvents() {
  const [shouldFetch, setShouldFetch] = useState(false);
  const { data, error } = useSWR(() => shouldFetch ? ['authoritySettingValidatorV1.pathRuleSet'] : null, pathRuleSetEventsFetcher);

  return {
    ensure: () => setShouldFetch(true),
    data, 
    error,
  }
}